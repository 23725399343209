import React from 'react';
import useColorStore from '../store/colorStore';
import PropTypes from 'prop-types';

const CustomIcon = ({ size = 20, className = 'custom-icon' }) => {
  // Получаем основной цвет из colorStore
  const strokeColor = useColorStore((state) => state.pageColors.primary);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <polyline
          points="14.2 3.4 8.4 12 15.6 12 9.8 20.6"
          fill="none"
          stroke={strokeColor} // Используем динамический цвет из стора
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

CustomIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default CustomIcon;
