import React, { useState } from 'react';
import { useUserStore } from '../store';

import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, collection, addDoc, onSnapshot } from 'firebase/firestore';
import { app } from '../config/firebaseConfig';

const TstSubscriptionButton = ({ priceId }) => {
  const { user, hasActiveSubscription, portalLink } = useUserStore();
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Failed to authenticate using popup:", error);
      alert(`Authentication error: ${error.message}`);
    }
  };

  const handleSubscriptionClick = () => {
    if (!user) {
      handleLogin();
    } else if (hasActiveSubscription) {
      handleManageSubscription(); // Directs user to the management portal
    } else {
      handleCheckout(priceId);
    }
  };

  const handleManageSubscription = () => {
    if (portalLink) {
      window.location.href = portalLink; // Redirect to the user portal for subscription management
    } else {
      console.error("No portal link available");
      alert("No link available to manage your subscription.");
    }
  };

  const handleCheckout = async (priceId) => {
    setLoading(true);
    const auth = getAuth(app);
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("User is not authenticated");
      alert("Please log in to initiate the checkout process.");
      setLoading(false);
      return;
    }

    try {
      const db = getFirestore(app);
      const checkoutSessionRef = collection(db, "users", currentUser.uid, "checkout_sessions");
      const docRef = await addDoc(checkoutSessionRef, {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.error) {
            console.error("An error occurred during checkout:", data.error);
            alert(`Checkout error: ${data.error}`);
            setLoading(false);
            unsubscribe();
            return;
          }
          if (data.url) {
            window.location.href = data.url;
          }
        } else {
          console.error("No data found in checkout session document.");
          setLoading(false);
        }
      }, error => {
        console.error("Failed to subscribe to checkout session:", error);
        setLoading(false);
      });
    } catch (error) {
      console.error("Failed to initiate checkout:", error);
      alert(`Failed to initiate checkout: ${error.message}`);
      setLoading(false);
    }
  };

  return (
    <button onClick={() => handleSubscriptionClick(priceId)} className="button" disabled={loading}>
      {loading ? 'Processing...' : (user ? (hasActiveSubscription ? 'Manage Subscription' : 'Subscribe') : 'Log in to Subscribe')}
    </button>
  );
};

export default TstSubscriptionButton;
