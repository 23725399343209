// HomePage.jsx
import React from 'react';
import BaseLayout from '../layouts/BaseLayout.jsx';
import LandingCategories from '../components/LandingCategories.jsx';

function HomePage() {
  return (
    <BaseLayout
      title="Awesome images of all kinds"
      description="Awesomeheap is a great collection of graphics, photos, icons, and more."
    >
      <div className="centered-container">
        <div className="page-container">
          <div
            style={{
              marginBottom: '50px',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ maxWidth: '900px' }}>
              <h1
                style={{
                  marginTop: '160px',
                  marginBottom: '10px',
                  fontSize: '48px',
                  lineHeight: '1.2',
                }}
              >
                Find premium royalty-free images for all your creative works.
              </h1>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ maxWidth: '600px', marginTop: '20px' }}>
                We are still in development. Some features may not work as expected, and there might be a few bugs. Thank you for your patience! 🐒
              </div>
            </div>
          </div>

          <LandingCategories />
        </div>
      </div>
    </BaseLayout>
  );
}

export default HomePage;
