import { create } from 'zustand';
import { setCSSVariables, setPageColors } from '../utils/colorUtils';

const useColorStore = create((set) => ({
  colors: {
    a: '#0279fd',  // Пример начального цвета
    b: '#cde2fb',
    c: '#9cc5fd',
    d: '#FFFFFF',
  },
  pageColors: {
    background: '#ffffff',  // Пример начального применяемого цвета
    primary: '#000000',
    backgroundShaded: '#eaeaea',  // Пример оттенка для background
    primaryShaded: '#FFFFFF',
    secondary: '#000000',
  },

  setColors: (newColors) => {
    set({ colors: newColors });
    set((state) => {
      const { background, primary, backgroundShaded, primaryShaded, secondary } = setPageColors(state.colors);
      setCSSVariables(background, primary, backgroundShaded, primaryShaded, secondary);
      return { pageColors: { background, primary, backgroundShaded, primaryShaded, secondary }};
    });
  },
}));

export default useColorStore;
