// Импорт необходимых функций из SDK
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';


// Конфигурация Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCKQ--O8L8s9_Lkbdq3V4koVG1uWSXvfHI",
    authDomain: "awesome-stock-6a4fc.firebaseapp.com",
    projectId: "awesome-stock-6a4fc",
    storageBucket: "awesome-stock-6a4fc.appspot.com",
    messagingSenderId: "40427956105",
    appId: "1:40427956105:web:d4f4e3d7135ce761666358",
    measurementId: "G-WJB7835TBH"
};

// Инициализация Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);


// Экспорт необходимых объектов и функций
export { 
    db, 
    auth, 
    provider, 
    signInWithPopup, 
    signOut, 
    collection, 
    app,
    addDoc  // Добавление экспорта функций Firestore
};

