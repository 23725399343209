import React from 'react';
import BaseLayout from '../layouts/BaseLayout';

function TermsPage() {
  return (
    <BaseLayout title="Terms" description="Terms and conditions">
      <div className="page-container-with-top-margin">
        <h1>Terms of Service (the “Terms”)</h1>
        <p>
          These Terms govern your use of Services (as defined below), our website{' '}
          <a href="https://awesomeheap.com/">https://awesomeheap.com/</a> (the “Website”), owned and
          operated by Siarhei Pratasavitski, NIP 1133073168, REGON 523267617 (acting under the name
          “Awesomeheap”, “we”, or “us”).
        </p>

        <h3>ABOUT THESE TERMS; CONSENT</h3>
        <p>
          These Terms, together with the Privacy Policy, and any other policy made available on the
          Website, is a binding contract between you (“Customer” or “you”) and Awesomeheap.
        </p>
        <p>
          By accessing or using our Website and Services (as defined below), you hereby agree to be
          bound by these Terms, Privacy Policy, any other policy made available on the Website, and
          all applicable laws and/or regulations, which may be in effect from time to time, and you
          agree that you are responsible for this compliance.
        </p>
        <p>
          Your access and use of Services is subject to the Privacy Policy, and any additional terms
          disclosed and agreed to by you if you purchase any additional services or products from
          us. Such terms are and will be incorporated by reference. If you do not wish to be bound
          by Privacy Policy, and any additional terms, you should not use our Services or purchase
          our Products.
        </p>
        <p>
          Awesomeheap reserves the right to unilaterally revise these Terms and all linked content
          from time to time, at its sole discretion, by updating this posting or linked content.
          Unless otherwise stated, the revised Terms will enter into force immediately. By
          continuing to use Services or purchasing our Products after modifications enter into
          force, the Customer indicates to agree to be bound by such modifications.
        </p>

        <h3>DEFINITIONS</h3>
        <p>In these Terms the following capitalized terms have the following definitions:</p>
        <ul>
          <li>
            <strong>“Account”</strong> means the account you open when you register on the Website.
          </li>
          <li>
            <strong>“Affiliate”</strong> means, with respect to either party, any other entity
            directly or indirectly controlling, controlled by, or under common control with such
            party (“control”); and “control” means, with respect to either party, the possession,
            directly or indirectly, of the power to direct the management and policies of such
            entity, through the ownership of voting securities or otherwise; “control” also means
            having a contractual arrangement with an individual or entity to perform administrative,
            managerial, group purchasing or similar services on behalf of the individual or entity.
          </li>
          <li>
            <strong>“Service”</strong> as defined in clause 3.1 below.
          </li>
          <li>
            <strong>“Customer”, “you” or “your”</strong> means a visitor of the Website, or a
            customer of our Services.
          </li>
          <li>
            <strong>“Visual Content”</strong> means any images, photos, and other visual content
            that can be purchased on the Website.
          </li>
        </ul>

        <h3>DESCRIPTION OF SERVICES; GRANT OF RIGHTS</h3>
        <p>
          Awesomeheap operates, hosts, and manages the Website, and performs services that enable
          you to purchase Visual Content (the “Services”). Awesomeheap grants to you a
          non-exclusive, non-transferable, non-sublicensable, revocable, and limited license to
          access and use Services if you fully comply with these Terms.
        </p>
        <p>
          Awesomeheap provides Services and sells Visual Content through the Website, using means of
          remote communication.
        </p>
        <p>The Website is serving Customers globally.</p>

        <h3>ELIGIBILITY</h3>
        <p>
          Our Website and Services can be used by an individual above eighteen (18) years of age.
        </p>
        <p>
          Any use of our Services for commercial purposes is strictly prohibited unless the Customer
          enters into a separate written agreement for commercial use with Awesomeheap.
        </p>

        <h3>INTELLECTUAL PROPERTY; PERMITTED USE OF VISUAL CONTENT</h3>
        <p>
          All content that is displayed on this Website which can be (without limitation), photos,
          images, digital and other visual art, and related metadata (jointly, the “Visual Content”)
          are protected by copyright, trademark, patent, trade secret and other intellectual
          property laws and treaties.
        </p>
        <p>
          Subject to your proper purchase of the general license for Visual Content, you are allowed
          to use Visual Content for your personal and non-commercial use (not for deriving any
          profit from resale or distribution) and download up to 50 icons/images.
        </p>

        <h3>RESTRICTIONS ON USE</h3>
        <p>
          You should not use Visual Content without an explicit consent of Awesomeheap which it can
          only give in written under a separate license agreement. Any Awesomeheap Content or other
          content on the Website does not constitute Awesomeheap’s permission unless you get a
          proper license.
        </p>
        <p>Except as otherwise explicitly provided in these Terms, you will not:</p>
        <ul>
          <li>Use Services for any illegal purpose or in violation of laws and regulations;</li>
          <li>
            Harass, bully, stalk, intimidate, assault, defame, harm, or otherwise abuse or cause
            psychological harm to any Customers or entities;
          </li>
          <li>Publish or share any Restricted Content as defined below;</li>
          <li>Use another Customer’s account or solicit passwords;</li>
          <li>Disclose proprietary or personal information of Customers without their consent;</li>
          <li>
            Reproduce, translate, enhance, decompile, disassemble, reverse engineer or create
            derivative works of Services or its technological features or measures;
          </li>
          <li>
            Rent, lease, sell, resell, loan, distribute, or sublicense access to any of Services;
          </li>
          <li>
            Circumvent or disable any security or technological features or measures of Services;
          </li>
          <li>
            Use Awesomeheap’s intellectual property rights or Visual Content without express prior
            written authorization or in violation of these Terms;
          </li>
          <li>
            Copy, distribute, or resell any of the information; Visual Content; any audio, visual,
            and audiovisual works, or other content made available on Services or compile or collect
            any content as part of a database or other work;
          </li>
          <li>
            Use any automated tool (e.g., robots, spiders, crawler) to access or use Services, or to
            store, copy, modify, distribute, or resell any Services;
          </li>
          <li>
            Upload viruses, or other malicious code or otherwise compromise the security of our
            Services;
          </li>
          <li>
            Circumvent or disable any rights management, usage rules, or other security features of
            Services;
          </li>
          <li>
            Use Services in a manner that overburdens, or that threatens the integrity, performance,
            or availability of, Services; or
          </li>
          <li>
            Remove, alter, or obscure any proprietary notices (including copyright and trademark
            notices) on any portion of Services or any content.
          </li>
        </ul>
        <p>
          Awesomeheap does not allow to remove, alter, or obscure any proprietary or copyright
          notices in Awesomeheap Content without our permission.
        </p>
        <p>
          If the Customer breaches any of these terms and conditions, the Customer’s right to use
          Services will be suspended or terminated in accordance with these Terms. In addition,
          using our Visual Content without permission constitutes copyright infringement and entails
          liabilities as set out in applicable law.
        </p>

        <h3>CUSTOMER CONTENT</h3>
        <p>
          For any image, footage, text, or any other visual content that you make available, post or
          upload to the Website, you represent and warrant that:
        </p>
        <ol type="i">
          <li>
            You have all necessary rights, powers, and authority to make available such content to
            the Website and grant the licenses set forth herein;
          </li>
          <li>
            Awesomeheap will not need to obtain licenses from any third party or pay royalties to
            any third party with respect to such content;
          </li>
          <li>
            Your content does not infringe any third party's rights, including intellectual property
            rights and privacy rights; and
          </li>
          <li>Your content does not violate these Terms or any applicable laws.</li>
        </ol>
        <p>
          You are solely responsible for ensuring that you have all rights, powers, and authority
          necessary to upload your content to the Website. Awesomeheap is not liable for any
          failure, delay or fault in your content being uploaded to the Website.
        </p>
        <p>
          By uploading your content, except for personal data which is governed by our Privacy
          Policy, you grant an irrevocable, non-exclusive, perpetual, worldwide, royalty-free, and
          sub-licensable (through multiple tiers) license to Awesomeheap to display, transmit,
          distribute, reproduce, publish, duplicate, adapt, modify, translate, create derivative
          works, and otherwise use any or all of your content in any form, media, or technology now
          known or not currently known in any manner and for any purpose which may be beneficial to
          the operation of the Website and the provision of any Services. You confirm and warrant to
          Awesomeheap that you have all the rights, power, and authority necessary to grant the
          above license. You shall not post or provide any confidential information. The above
          licenses will continue unless and until you remove your content from the Website, in which
          case the licenses will terminate within a commercially reasonable period of time.
          Notwithstanding the foregoing, the license for legal archival/preservation purposes will
          continue indefinitely.
        </p>
        <p>
          Any content found to be in breach of these Terms shall be promptly removed, and such
          breach may warrant the termination and/or deletion of your Account without prior
          notification. Should you encounter any content which you suspect violates these Terms, we
          encourage you to report it to us.
        </p>
        <p>
          Awesomeheap may, at its discretion, engage in the monitoring, review, or alteration of any
          content, although it is not bound by obligation to do so. Awesomeheap reserves the
          exclusive right to expunge any material published or uploaded on the Website that it
          reasonably discerns as unlawful, potentially entailing liability for Awesomeheap or other
          Customers, contravening these Terms, or deemed inappropriate at the discretion of
          Awesomeheap.
        </p>
        <p>
          Awesomeheap may be obliged to cooperate with governmental authorities, private
          investigators, and/or aggrieved third parties in the investigation of any suspected
          criminal or civil transgressions. Further, you agree that Awesomeheap may share your
          account information, including your content, if required to do so by law or upon a good
          faith belief that such access, preservation, or disclosure is reasonably necessary to:
        </p>
        <ol type="i">
          <li>Comply with any legal investigation;</li>
          <li>Enforce these Terms;</li>
          <li>Respond to claims that any content violates the rights of third parties;</li>
          <li>Respond to your requests for customer service;</li>
          <li>
            Protect the rights, property or personal safety of Awesomeheap; or
          </li>
          <li>
            Investigate, prevent, or take other action regarding illegal activity, suspected fraud
            or other wrongdoing.
          </li>
        </ol>
        <p>
          You may not use any Visual Content for any purpose without first obtaining a relevant
          license. Any use of Visual Content by you shall be governed by the applicable license
          agreement separately entered into between you and Awesomeheap.
        </p>

        <h3>RESTRICTED CONTENT; REPORTING</h3>
        <p>
          Customers shall not make available, post or upload to the Website any content or other
          material (the “Restricted Content”) that:
        </p>
        <ul>
          <li>
            Violates any applicable law or regulation, including any third party intellectual
            property rights;
          </li>
          <li>
            Is obscene, sexually explicit, pornographic, unethical, defamatory, libelous,
            fraudulent, vulgar, discriminatory or otherwise inappropriate in nature;
          </li>
          <li>
            Contains viruses, spyware, adware, pirated software; digital rights protection
            circumvention or hacking tools, spamming tools or any other harmful code or activity
            that could, in an impermissible manner, access or use, impair or injure any data,
            devices, computer systems, or software;
          </li>
          <li>
            Endorses or encourages violence, hatred, revenge, racism, sexism, victimization,
            discrimination of any kind;
          </li>
          <li>
            Is sent without a proper authorization or consent by a licensor; or
          </li>
          <li>Solicits login information belonging to someone else.</li>
        </ul>
        <p>
          Awesomeheap will not be liable for any breach of these Terms or liability incurred by you
          as a direct or indirect result of circumstances which are, in whole or part, outside of
          the reasonable control of Awesomeheap or to the extent that you failed to mitigate such
          liability.
        </p>
        <p>
          You acknowledge and agree that if at any time circumstances arise which would reasonably
          be expected to compromise the security of the Website or the provision of Services,
          Awesomeheap may suspend all or part of Services immediately and until security has been
          restored. In such an event, Awesomeheap and you must use their best endeavours to resolve
          such issues in order to reinstate Services at the earliest possible opportunity.
        </p>
        <p>
          We aim to create a positive community and do not tolerate inappropriate behavior. We
          expect that all our Customers will respect our rules.
        </p>
        <p>
          If you notice any disrespectful or inappropriate behavior, we encourage you to report it.
          We will investigate all the circumstances and may take necessary actions against those
          Customers who violate our Terms.
        </p>

        <h3>REGISTRATION</h3>
        <p>In order to use Services, you must:</p>
        <ul>
          <li>Complete the registration process through the Website and set up an Account;</li>
          <li>Agree to the terms set out in these Terms; and</li>
          <li>Provide true, complete and up to date contact information.</li>
        </ul>
        <p>
          Each time you access the Website and use Services, you represent and warrant that there
          have been no changes to the information provided above. Awesomeheap may refuse to provide
          Services, close your Account if there has been a material change to the information
          provided above.
        </p>
        <p>
          You must not allow any person to access your Account or use Services through your Account
          other than Customer. All Services are personal to you. You acknowledge and agree that you
          are solely responsible for all activity that occurs on or with your Account.
        </p>
        <p>
          We keep your personal data safe and confidential as described in the Privacy Policy.
        </p>
        <p>
          The Customer shall be responsible for the security of the Account on the Website and keep
          their username and password confidential. You shall be solely responsible for any actions
          (and their consequences) in and with Services through your Account, including any
          transactions made through it.
        </p>
        <p>
          In the event that the Customer provides incorrect or inaccurate data, including in
          particular an incorrect or inaccurate address, Awesomeheap will not be liable for any
          failure to deliver or delay in the delivery of the Goods or the failure to perform the
          Service, to the fullest extent permitted by law.
        </p>

        <h3>WEBSITE AND SERVICES AVAILABILITY</h3>
        <p>
          Awesomeheap uses commercially reasonable efforts to maintain our Website and Services on
          twenty-four (24) hours a day, seven (7) days a week basis. We provide 99% of our Website
          and Services availability annually. However, our Website and Services may be inaccessible
          or inoperable due to equipment malfunctions, periodic maintenance, repairs, or
          replacements that we undertake from time to time. Our Website and Services can be
          unavailable due to causes beyond our reasonable control, such as failure of
          telecommunication or digital transmission links, hostile network attacks, network
          congestion, or other failures.
        </p>

        <h3>FEES; SUBSCRIPTIONS AND PAYMENT</h3>
        <p>
          Certain Visual Content is offered for free. We also make available Visual Content that you
          can purchase for a fee, and/or buy a monthly or annual subscription.
        </p>
        <p>
          All fees are indicated on the Website. We may change any fee from time to time and will
          update you by placing revised fees on our Website. Awesomeheap can increase, adjust and
          change prices at any time and without any notice.
        </p>
        <p>We use Stripe as a payment method.</p>
        <p>
          To the extent any amounts owed under these Terms cannot be collected from your payment
          method(s), you are solely responsible for paying such amounts by other means.
        </p>
        <p>
          The Customer will be solely responsible for payment of any taxes, duties, or levies as
          applicable to your purchase.
        </p>
        <p>
          You may cancel your subscription a day before the renewal date, otherwise we will bill for
          the next subscription using the payment method you provided us with.
        </p>
        <p>
          In the inability to fulfill the Customer's order for any reason, Awesomeheap will inform
          the Customer by sending information to their e-mail address within 7 (seven) days from the
          order date.
        </p>

        <h3>REFUND POLICY</h3>
        <p>
          Awesomeheap may consider giving a refund if you submit your refund request stating a
          reason for the request within 14 days after the date of your purchase.
        </p>
        <p>Awesomeheap will review the refund request and provide a refund if:</p>
        <ul>
          <li>The Customer did not mean to make the purchase;</li>
          <li>A child/minor made the purchase without the Customer’s permission;</li>
          <li>Visual Content is faulty or defective.</li>
        </ul>
        <p>
          After the first 14 days you will not be eligible for a refund (or any alternative remedy)
          unless Visual Content is faulty or defective.
        </p>

        <h3>PROPRIETARY RIGHTS</h3>
        <p>
          Awesomeheap is the sole owner and lawful licensee of all rights, title, and interests
          available on the Website and Services. You acknowledge and agree that the Website and
          Services contain proprietary and confidential information that is protected by applicable
          intellectual property and other worldwide laws. All title, ownership and intellectual
          property rights on the Website, Services, and its content shall remain with Awesomeheap,
          our Affiliates, or licensors of the Website content, unless otherwise stated in these
          Terms. All rights not otherwise claimed under these Terms or by Awesomeheap are hereby
          reserved.
        </p>
        <p>
          You further acknowledge and agree that the Website and Services are protected by
          copyrights, trademarks (whether registered or being under registration), service marks,
          patents, or other proprietary rights and laws. Except as expressly permitted by applicable
          law or as authorized by Awesomeheap or the applicable licensor, you agree not to modify,
          rent, lease, loan, sell, distribute, transmit, broadcast, publicly perform or create
          derivative works based on Services, such content, or the Website, in whole or in part.
        </p>
        <p>
          We may use services of third parties involved in the provision of Services. You may not
          violate proprietary rights and use any trademark, service mark, or logo of such
          independent third parties without prior written approval from such parties.
        </p>

        <h3>THIRD-PARTY SERVICE</h3>
        <p>
          Our Website may display, or contain links to, third-party products, services, and
          websites. Any opinions, advice, statements, services, offers, or other information that
          constitutes part of the content expressed, authored, or made available by other users or
          other third parties, or which is accessible through or may be located using the Website or
          our Services (collectively, “Third Party Content”) are those of the respective authors or
          producers and not of us or our shareholders, directors, officers, employees, agents, or
          representatives.
        </p>
        <p>
          We do not control Third-Party Content and do not guarantee the accuracy, integrity, or
          quality of such Third-Party Content. We are not responsible for the performance of, we do
          not endorse, and we are not responsible or liable for, any Third-Party Content or any
          information or materials advertised on the Website. By using our Website or Services, you
          may be exposed to content that is offensive, indecent, or objectionable. We are not
          responsible or liable, directly or indirectly, for any damage or loss caused to you by
          your use of or reliance on any goods, services, or information available on or through any
          third-party service or Third-Party Content. It is your responsibility to evaluate the
          information, opinion, advice, or other content available on and through our Products.
        </p>

        <h3>DISCLAIMER</h3>
        <p>
          Your use of Services, or items obtained through them is at your own risk. Services are
          provided on an “as is” and “as available” basis, without any warranties of any kind,
          either express or implied. Neither Awesomeheap nor any Affiliate or person associated with
          Awesomeheap makes any warranty or representation with respect to the completeness,
          security, reliability, quality, accuracy, or availability of the Website and Services.
          Without limiting the foregoing, neither Awesomeheap nor anyone associated with Awesomeheap
          represents or warrants that Services will be accurate, reliable, error-free, or
          uninterrupted, that defects will be corrected.
        </p>
        <p>
          Awesomeheap hereby disclaims all warranties of any kind, whether express or implied,
          statutory, or otherwise, including but not limited to any warranties of merchantability,
          non-infringement, and fitness for a particular purpose.
        </p>

        <h3>INDEMNIFICATION</h3>
        <p>
          To the full extent permitted by applicable law, the Customer shall defend, indemnify and
          hold harmless Awesomeheap (and our directors, owners, officers, managers, assigns,
          employees, consultants, and agents), its Affiliates, and its licensors, and each of their
          respective employees, officers, directors, and representatives from and against any
          third-party claims, damages, losses, liabilities, costs, and expenses (including
          reasonable attorney’s fees) arising out of or relating to any third party claim
          concerning:
        </p>
        <ol type="a">
          <li>Customer’s use of Services;</li>
          <li>Customer’s breach of these Terms, Privacy Policy, or violation of applicable law;</li>
          <li>Breach of any obligation or duty the Customer owes to a third party.</li>
        </ol>

        <h3>LIMITATION OF LIABILITY</h3>
        <p>
          <strong>
            Awesomeheap WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
            PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED
            OF THE POSSIBILITY OF THESE DAMAGES), RESULTING FROM CUSTOMER’S USE OF SERVICES. UNDER
            NO CIRCUMSTANCES WILL THE TOTAL LIABILITY OF Awesomeheap ARISING OUT OF OR RELATED TO
            THE CUSTOMER’S USE SERVICES (INCLUDING BUT NOT LIMITED TO WARRANTY CLAIMS), REGARDLESS
            OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT,
            OR OTHERWISE, EXCEED THE AMOUNTS THAT THE CUSTOMER HAS PAID TO Awesomeheap DURING THE
            PRIOR 12 MONTHS UNDER THESE TERMS.
          </strong>
        </p>

        <h3>TERM, SUSPENSION AND TERMINATION</h3>
        <p>
          These Terms will remain in full force and effect while the Customer uses Services.
        </p>
        <p>
          We reserve the right to restrict your access (with or without notification to you),
          temporarily or indefinitely, suspend Services if:
        </p>
        <ul>
          <li>You breach these Terms or other policies;</li>
          <li>
            We believe that your actions may cause any loss or liability to any third parties, our
            Affiliates, or to us; or
          </li>
          <li>
            We suspect or become aware that you have provided false or misleading information to us.
          </li>
        </ul>
        <p>
          Any terms and conditions of these Terms that may survive termination shall survive
          termination, including, without limitation, ownership provisions, warranty disclaimers,
          indemnification, and limitations of liability.
        </p>
        <p>You can delete your Account in Account settings at any time.</p>

        <h3>EFFECT OF TERMINATION</h3>
        <p>
          You will promptly cease use of Services and promptly irretrievably destroy all content
          from Services and/or all proprietary data of Awesomeheap obtained as a result of these
          Terms during any period of suspension, or upon termination of these Terms.
        </p>

        <h3>ACCESS TO SERVICES</h3>
        <p>
          Awesomeheap does not provide the Customer with the equipment to use Services. The Customer
          is responsible for all fees charged by third parties related to the use of Services (e.g.,
          charges by Internet service providers).
        </p>
        <p>
          The Customer is responsible for monitoring the use of Services, including payment of all
          fees and/or taxes related to such access and use. The Customer agrees that Awesomeheap is
          permitted to request and the Customer hereby consents to provide Awesomeheap information
          related to your use of Services for auditing purposes.
        </p>

        <h3>NOTIFICATIONS</h3>
        <p>
          The Customer hereby consents to electronically receive and access, via email or the
          Website, all records and notices for Services provided to the Customer under these Terms
          that Awesomeheap would otherwise be required to provide to the Customer in paper form.
          However, Awesomeheap reserves the right, in its sole discretion, to communicate with the
          Customer via mail services using the address under which the Account is registered.
        </p>

        <h3>APPLICABLE LAW</h3>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of Poland,
          without regard to conflict of law principles.
        </p>
        <p>
          Notwithstanding the specified agreement on jurisdiction, the Customer and Awesomeheap
          shall, if any dispute arises, attempt to settle it by mutual negotiations.
        </p>

        <h3>NEWSLETTERS</h3>
        <p>
          The Customer may consent to receive commercial information, including commercial
          information sent by electronic means, by selecting the appropriate option in the
          registration form or on the Website. In the case of such consent, the Customer will
          receive Awesomeheap’s newsletter to the e-mail address provided by the Customer.
        </p>
        <p>The Customer may unsubscribe from the newsletter at any time.</p>

        <h3>MISCELLANEOUS</h3>
        <p>
          <strong>Invalid Provision.</strong> If any provision of these Terms is held to be invalid,
          non-binding, or unenforceable, the remaining provisions shall remain valid and be
          enforced. In such event, such invalid provision shall nonetheless be enforced to the
          fullest extent permitted by applicable law, and you will at least agree to accept a
          similar effect as the invalid, unenforceable, or non-binding provision, given the contents
          and purpose of these Terms.
        </p>
        <p>
          <strong>Entire Agreement.</strong> These Terms, including any applicable Privacy Policy,
          constitute the entire and exclusive understanding and agreement between the Customer and
          Awesomeheap regarding its subject matter.
        </p>
        <p>
          <strong>Assignment.</strong> Except as expressly provided in these Terms, neither party
          may assign any of its rights or obligations under this Agreement without the other party’s
          prior written consent, which consent shall not be unreasonably withheld. Failure to obtain
          such prior written consent shall render any attempted assignment void and of no force and
          effect. Notwithstanding the foregoing, Awesomeheap may assign, in whole or in part, its
          rights or obligations pursuant to these Terms to:
        </p>
        <ol type="i">
          <li>An Affiliate;</li>
          <li>
            Any entity in connection with a divestiture, sale, merger or transfer of lines of
            business, divisions or business units.
          </li>
        </ol>
        <p>
          <strong>Force Majeure.</strong> Neither party will be liable for delays or any failure to
          perform under these Terms due to causes beyond its reasonable control and to the extent
          not occasioned by the fault or negligence of the delayed party, including fire; explosion;
          flood or other natural catastrophe; governmental legislation, acts, orders, or regulation;
          terrorist acts; or strikes or labor difficulties (each a “Force Majeure Event”). Any delay
          as a result of a Force Majeure Event shall last only as long as the Force Majeure Event
          remains beyond the reasonable control of the delayed party; provided, however, that the
          delayed party shall use its best efforts to minimize the delays caused by any such Force
          Majeure Event.
        </p>
        <p>
          <strong>Subcontractors.</strong> Awesomeheap may use subcontractors, provided that
          Awesomeheap remains responsible for their compliance with these Terms and for its overall
          performance under these Terms.
        </p>

        <h3>Contact Us</h3>
        <p>
          For any queries or suggestions, please contact our Customer Care at:{' '}
          <a href="mailto:info@davooda.com">info@davooda.com</a>
        </p>
      </div>
    
    </BaseLayout>
  );
}

export default TermsPage;
