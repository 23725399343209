import React, { useState } from 'react';
import { useAssetStore } from '../store_asset_detail';
import { useUserStore } from '../store';
import useAdditionalStorage from '../additional_storage'; 

const DownloadImageButton = () => {
    // Используем селекторы для получения только необходимых данных
    const asset = useAssetStore(state => state.asset);
    const user = useUserStore(state => state.user);
    const { togglePopup } = useAdditionalStorage(); // Использование togglePopup из useAdditionalStorage
    const [isLoading, setIsLoading] = useState(false);

    // Функция для определения текста кнопки
    const getButtonText = () => {
        if (!asset) {
            return 'Download Image';
        }
        if (!user) {
            return asset.is_free ? 'Download Hi Res for Free' : 'Sign in to Download';
        }
        return isLoading ? 'Loading...' : 'Download';
    };

    const handleDownloadImage = () => {
        if (!asset) {
            alert('Asset not available for download.');
            return;
        }

        if (!user) {
            if (asset.is_free) {
                proceedDownload();
            } else {
                redirectToLogin();
            }
        } else {
            proceedDownload();
        }
    };

    const redirectToLogin = () => {
        const currentImageUrl = encodeURIComponent(window.location.href);
        window.location.href = `/login?next=${currentImageUrl}`;
    };

    const proceedDownload = async () => {
        setIsLoading(true);

        const url = 'https://solitary-bread-0f02.willsavetheworld.workers.dev/';
        const bodyData = JSON.stringify({
            imageURL: asset.url_large,
            is_free: asset.is_free,
            token: user ? user.accessToken : null
        });

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: bodyData
            });

            if (!response.ok) {
                throw new Error(`Server responded with an error: ${response.status}`);
            }

            const blob = await response.blob();
            const downloadUrl = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.target = '_blank';
            link.download = `${asset.slug || 'image'}.jpeg`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(downloadUrl);
            if (asset.is_free) { // Проверка, что изображение бесплатное перед показом попапа
                togglePopup(); // Показать попап при успешной загрузке бесплатного изображения
            }
        } catch (error) {
            alert('Download failed: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <button onClick={handleDownloadImage} disabled={isLoading} className='download-button'>
            {getButtonText()}
        </button>
    );
};

export default DownloadImageButton;
