// src/components/IconDetail.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import IconDisplay from './IconDisplay';
import ColorSetPreview from './ColorSetPreview';
import SimilarIcons from './SimilarIcons';
import '../../styles/IconDetail.css';
import useColorStore from '../../store/colorStore';

const initialColorSets = [
  {
    name: 'Custom Set 1',
    combinations: [
      { a: '#f4f6fb', b: '#73b4f3', c: '#d0e7fd', d: '#293cf5' },
      { a: '#060a2e', b: '#293cf5', c: '#73b4f3', d: '#b3d7f7' }
    ],
    currentIndex: 0,
  },
  {
    name: 'Color Set 1',
    combinations: [
      { a: '#143178', b: '#f8d256', c: '#ffffff', d: '#3e6edc' },
    ],
    currentIndex: 0,
  },
  {
    name: 'Color Set 7',
    combinations: [
      { a: '#ffffff', b: '#e86f60', c: '#c32922', d: '#f58f83' },
    ],
    currentIndex: 0,
  },
  {
    name: 'Color Set 8',
    combinations: [
      { a: '#476ba3', b: '#e8e177', c: '#72ae92', d: '#fefcf7' },
    ],
    currentIndex: 0,
  },
  {
    name: 'Color Set 10',
    combinations: [
      { a: '#3d3d3d', b: '#d7dee0', c: '#91805c', d: '#ffffff' },
      { a: '#FFFFFF', b: '#af9f87', c: '#7e898b', d: '#cabaa1' },
    ],
    currentIndex: 0,
  },
  {
    name: 'Color Set 11',
    combinations: [
      { a: '#ffffff', b: '#d8df50', c: '#ce5236', d: '#fae46a' },
    ],
    currentIndex: 0,
  },
];

const IconDetail = ({ slug, BACKEND_URL, title = 'Icon Title', keywords = 'keyword1, keyword2, keyword3' }) => {
  const [svgData, setSvgData] = useState('');
  const [processedSvgData, setProcessedSvgData] = useState('');
  const [colorSets, setColorSets] = useState(initialColorSets);
  const [activeSetName, setActiveSetName] = useState('Custom Set 1');
  const [assetTitle, setAssetTitle] = useState(title);
  const [assetKeywords, setAssetKeywords] = useState(keywords);
  const [collection, setCollection] = useState('');

  const colors = useColorStore((state) => state.colors);

  useEffect(() => {
    const fetchSvgData = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/asset`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ slug }),
        });

        if (!response.ok) throw new Error('Network error');

        const asset = await response.json();
        setSvgData(asset.svg_data);
        setAssetTitle(asset.title || title);
        setAssetKeywords(asset.keywords || keywords);
        if (asset.collection) setCollection(asset.collection);
      } catch (error) {
        console.error('Error loading SVG:', error);
      }
    };

    fetchSvgData();
  }, [slug, BACKEND_URL, title, keywords]);

  const handleSvgUpdate = (updatedSvg) => {
    setProcessedSvgData(updatedSvg);
  };

  const description = `Beautiful handcrafted ${assetTitle} icon`;

  return (
    <div className="container">
      {svgData ? (
        <>
          <Helmet>
            <title>{assetTitle} vector icon</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={assetKeywords} />
            <meta property="og:title" content={assetTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`${BACKEND_URL}/path-to-icon-image/${slug}.svg`} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={assetTitle} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={`${BACKEND_URL}/path-to-icon-image/${slug}.svg`} />
          </Helmet>

          <div className="mainContent">
            <div className="svgContainer" style={{ backgroundColor: colors.a }}>
              <IconDisplay
                svgData={svgData}
                colors={{ b: colors.b, c: colors.c, d: colors.d }}
                assetTitle={assetTitle}
                assetKeywords={assetKeywords}
                onSvgUpdate={handleSvgUpdate}
              />
            </div>

            <div className="controls">
              <div className="titleKeywords">
                <h1 className="asset-title">{assetTitle}</h1>
                {collection && (
                  <p className="collection">
                    <a href={`/collection/${collection}`}>{collection} Collection</a> Originally crafted at a resolution of 64×64 pixels, Drawed by real human
                  </p>
                )}
              </div>

              <div className="colorSets">
                {colorSets.map((set) => (
                  <ColorSetPreview
                    key={set.name}
                    colors={set.combinations[set.currentIndex]}
                    isActive={activeSetName === set.name}
                    setName={set.name}
                    setActiveSetName={setActiveSetName}
                  />
                ))}
              </div>

              <button
                onClick={() => {
                  if (!processedSvgData) {
                    console.error('No processed SVG data available for download.');
                    return;
                  }

                  try {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(processedSvgData, 'image/svg+xml');
                    const svgElement = xmlDoc.documentElement;

                    svgElement.setAttribute('width', '64');
                    svgElement.setAttribute('height', '64');

                    const serializer = new XMLSerializer();
                    const resizedSvg = serializer.serializeToString(xmlDoc);

                    const blob = new Blob([resizedSvg], { type: 'image/svg+xml' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `${slug}.svg`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                  } catch (error) {
                    console.error('Error processing SVG for download:', error);
                  }
                }}
                className="download-button"
              >
                Download SVG
              </button>
            </div>
          </div>

          <SimilarIcons slug={slug} BACKEND_URL={BACKEND_URL} />
        </>
      ) : (
        <p>Loading SVG...</p>
      )}
    </div>
  );
};

export default IconDetail;
