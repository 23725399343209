// src/pages/TemplateDetailPage.jsx

import React from 'react';
import { useParams } from 'react-router-dom';
import TemplateDetailComponent from '../components/MakeMeCover/TemplateDetailComponent.jsx';


const TemplateDetailPage = () => {
  const { slug } = useParams();
  const PUBLIC_BACK_URL = process.env.REACT_APP_BACK_URL || '';

  return (
      <div>
        <TemplateDetailComponent slug={slug} PUBLIC_BACK_URL={PUBLIC_BACK_URL} />
      </div>
  );
};

export default TemplateDetailPage;
