import React from 'react';
import useColorStore from '../store/colorStore';
import PropTypes from 'prop-types';

const LensIcon = ({ size = 20, className = 'search-lens-icon' }) => {
  // Используем хук useColorStore для получения основного цвета напрямую
  const strokeColor = useColorStore((state) =>  state.pageColors.primary);
  
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="7.5"
        cy="7.5"
        r="5.5"
        stroke={strokeColor} // Используем динамический цвет из стора
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="16.2"
        y1="16.2"
        x2="11.4"
        y2="11.3"
        stroke={strokeColor} // Используем тот же динамический цвет для линии
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LensIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default LensIcon;
