// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './pages/App';


// Найдите элемент с id 'root' в вашем HTML
const container = document.getElementById('root');

if (!container) {
  throw new Error("Элемент с id 'root' не найден в HTML.");
}

// Создайте корень
const root = createRoot(container);

// Выполните начальный рендер без передачи контейнера во второй аргумент
root.render(
//   <React.StrictMode>
    <App />
//   </React.StrictMode>
);
