import React, { useEffect, useState } from 'react';
import '../../styles/SimilarIcons.css';
import { Link } from 'react-router-dom';
import IconDisplay from './IconDisplay';
import useColorStore from '../../store/colorStore';

// Utility function to determine contrasting text color
const getContrastingTextColor = (bgColor) => {
    // Remove '#' if present
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    
    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    
    // Return black for light backgrounds, white for dark backgrounds
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const SimilarIcons = ({ slug, BACKEND_URL }) => {
    const [icons, setIcons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const colors = useColorStore((state) => state.colors);

    // Determine the text color based on background color
    const textColor = getContrastingTextColor(colors.a);

    // Function to fetch and process similar icons
    const fetchSimilarIcons = async () => {
        if (!slug || !BACKEND_URL) {
            console.log('Slug or BACKEND_URL not provided.');
            return;
        }

        try {
            setIsLoading(true);
            setIcons([]); // Clear previous icons
            setError(null); // Clear previous errors
            console.log('Fetching similar icons for:', slug);

            const response = await fetch(`${BACKEND_URL}/similar_icons`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ slug, collection: "polish-blue" }), // Adjust the body as needed
            });

            if (!response.ok) {
                throw new Error('Failed to fetch similar icons.');
            }

            const data = await response.json();
            console.log('Fetched similar icons with svg_data:', data.items);

            // Filter data: exclude original icon and ensure necessary fields
            const filteredItems = data.items.filter(
                (item) => item.slug && item.url_small && item.slug !== slug
            );

            if (filteredItems.length === 0) {
                console.log('No similar icons found after filtering.');
                setIcons([]);
                return;
            }

            // Map the items to the expected icon format
            const uniqueIcons = [];
            const seenSlugs = new Set();

            filteredItems.forEach((item) => {
                if (!seenSlugs.has(item.slug)) {
                    seenSlugs.add(item.slug);
                    uniqueIcons.push({
                        src: item.url_small,
                        width: item.url_small_width,
                        height: item.url_small_height,
                        alt: item.title || 'Similar Icon',
                        key: item.slug, // Ensure slug is unique
                        url: item.media_type === 'icon' ? `/icon/${item.slug}` : `/photo/${item.slug}`,
                        isFree: item.is_free,
                        svgData: item.svg_data,
                    });
                }
            });

            console.log('Unique icons after processing:', uniqueIcons);
            setIcons(uniqueIcons);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSimilarIcons();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug, BACKEND_URL]);

    return (
        <div className="similar-icons-container">
            <h3>Similar Icons</h3>
            {isLoading && <div style={{ color: textColor }}>Loading similar icons...</div>}
            {error && <div className="error-message" style={{ color: textColor }}>{error}</div>}
            {!isLoading && !error && icons.length > 0 && (
                <div className="icons-grid">
                    {icons.map((icon) => (
                        <div key={icon.key} className="icon-item">
                            <Link to={icon.url}>
                                <div
                                    className="icon-display-wrapper"
                                    style={{
                                        backgroundColor: colors.a,
                                        transition: 'background-color 0.3s ease, color 0.3s ease',
                                    }}
                                >   
                                    <IconDisplay
                                        svgData={icon.svgData}
                                        colors={{ b: colors.b, c: colors.c, d: colors.d }}
                                        assetTitle={icon.alt}
                                        assetKeywords=""
                                    />
                                </div>
                            </Link>
                            {icon.isFree && <span className="free-badge">Free</span>}
                        </div>
                    ))}
                </div>
            )}
            {!isLoading && !error && icons.length === 0 && (
                <div style={{ color: textColor }}>No similar icons found.</div>
            )}
        </div>
    );
};

export default SimilarIcons;
