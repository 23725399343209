// TstPricingComponent.js
import React, { useState, useEffect } from 'react';
import '../styles/PricingComponent.css';
import { useUserStore } from '../store';
import TstSubscriptionButton from './SubscriptionButton';

const plans = {
  premium: {
    monthly: { price: 14.99, stripePriceId: 'price_1Pre5pFypT1ty0xHD39yfJbz' },
    yearly: { price: 149.99, stripePriceId: 'price_1Pre6YFypT1ty0xHeeNcSDiM' },
    downloads: 'up to 250 downloads'
  },
  pro: {
    monthly: { price: 29.99, stripePriceId: 'price_1Pre96FypT1ty0xHQEsFI48M' },
    yearly: { price: 299.99, stripePriceId: 'price_1Pre9bFypT1ty0xHdwS0gYIm' },
    downloads: 'up to 600 downloads'
  }
};

const TstPricingComponent = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');
  const { subscriptions, loadingSubscriptions, subscriptionError } = useUserStore();
  const [currentPriceId, setCurrentPriceId] = useState(null);

  useEffect(() => {
    const activeSubscription = subscriptions?.find(sub => sub.status === 'active');
    setCurrentPriceId(activeSubscription ? activeSubscription.items[0].price.id : null);
  }, [subscriptions]);

  if (loadingSubscriptions) return <p>Loading plans...</p>;
  if (subscriptionError) return <p>Error: {subscriptionError}</p>;

  return (
    <div className="componentContainer">
      <h1 className="title">Choose Your Plan</h1>
      <div className="toggleContainer" onClick={() => setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly')}>
        <div className={`movingBackground ${billingCycle === 'monthly' ? '' : 'right'}`}></div>
        <div className={`label ${billingCycle === 'yearly' ? 'labelActive' : ''}`}>Monthly Billing</div>
        <div className={`label ${billingCycle === 'monthly' ? 'labelActive' : ''}`}>Yearly Billing</div>
      </div>
      <div className="plansContainer">
        {Object.entries(plans).map(([key, plan]) => (
          <div key={key} className={`plan ${currentPriceId === plan[billingCycle].stripePriceId ? 'active' : ''}`}>
            <h2 className="planTitle">{key.charAt(0).toUpperCase() + key.slice(1)}</h2>
            <p className="price">${plan[billingCycle].price} <small>{billingCycle === 'monthly' ? 'per month' : 'per year'}</small></p>
            <p className="description">{plan.downloads}</p>
            {currentPriceId === plan[billingCycle].stripePriceId && <span className="activeLabel">Active</span>}
            <TstSubscriptionButton priceId={plan[billingCycle].stripePriceId} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TstPricingComponent;
