import React, { useEffect, useState } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { app } from '../config/firebaseConfig'; // Make sure the path is correct
import '../styles/Login.css'; // Assuming you have extracted styles into this file
import '../styles/Global.css';
import '../styles/Footer.css';
import '../styles/Navbar.css';
import '../styles/Popupmakealink.css';

const LoginPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [next, setNext] = useState('');

  useEffect(() => {
    const auth = getAuth(app);
    const checkAuthStatus = () => {
      onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          setUser(currentUser);
          const params = new URLSearchParams(window.location.search);
          const nextUrl = params.get('next') || '/';
          setNext(nextUrl);
        } else {
          setUser(null);
        }
        setLoading(false);
      });
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    if (user && next) {
      window.location.href = next;
    }
  }, [user, next]);

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleAppleLogin = () => {
    const provider = new OAuthProvider('apple.com');
    const auth = getAuth(app);
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {user ? (
        <div>
          <h1>Welcome, {user.displayName}!</h1>
          <p>Email: {user.email}</p>
        </div>
      ) : (
        <div className='centered_container'>
        <a href="/"><img src="awesomeheap_logo.svg" alt="Logo" className="logo_big" /></a>
          <h1 className="login-h1">Sign In</h1>
          <button className="google-sign-in-button" onClick={handleGoogleLogin}>
            <img src="/images/google_icon.svg" alt="Google sign-in" className="google-icon" />
            Continue with Google
          </button>
          <button className="apple-sign-in-button" onClick={handleAppleLogin}>
            <img src="/images/apple_icon.svg" alt="Apple sign-in" className="apple-icon" />
            Continue with Apple
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
