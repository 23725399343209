export const hexToRgb = (hex) => {
    let cleanedHex = hex.replace('#', '');
    if (cleanedHex.length === 3) {
      cleanedHex = cleanedHex.split('').map(c => c + c).join('');
    }
    const bigint = parseInt(cleanedHex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };
  
  
export const getRelativeLuminance = (hex) => {
  const { r, g, b } = hexToRgb(hex);
  const rgb = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
};

  
  export const getContrastRatio = (hex1, hex2) => {
    const L1 = getRelativeLuminance(hex1);
    const L2 = getRelativeLuminance(hex2);
    return L1 > L2 ? (L1 + 0.05) / (L2 + 0.05) : (L2 + 0.05) / (L1 + 0.05);
  };
  
  export const adjustColor = (hex, percent) => {
    const { r, g, b } = hexToRgb(hex);
    const adjust = (c) => {
      const adjusted = Math.round(c * (1 + percent / 100));
      return Math.min(255, Math.max(0, adjusted));
    };
    return `#${((1 << 24) + (adjust(r) << 16) + (adjust(g) << 8) + adjust(b)).toString(16).slice(1)}`;
  };
  
  export const setPageColors = ({ a, b, c, d }) => {
    let background = a;
    const otherColors = [b, c, d];
    let primary = otherColors[0]; // Инициализация primary первым элементом для сравнения
    let maxContrast = getContrastRatio(background, primary);
  
    console.log(`Начальный контраст между ${background} и ${primary}: ${maxContrast}`);
  
    // Перебор остальных цветов для нахождения максимального контраста
    otherColors.forEach(color => {
      const contrast = getContrastRatio(background, color);
      console.log(`Контраст между ${background} и ${color}: ${contrast}`);
      if (contrast > maxContrast) {
        maxContrast = contrast;
        primary = color;
      }
    });
  
    console.log(`Максимальный контраст: ${maxContrast}, выбран цвет: ${primary}`);
  
    // Проверка достаточности контраста и корректировка primary, если нужно
    primary = maxContrast < 4.5 ? adjustColor(primary, 10) : primary;
    const backgroundShaded = adjustColor(background, -10);
    const primaryShaded = adjustColor(primary, -10);
    let secondary = d; // По умолчанию используем 'd' как вторичный цвет
  
    return { background, primary, backgroundShaded, primaryShaded, secondary };
  };
  
  
  
  export const setCSSVariables = (background, primary, backgroundShaded, primaryShaded, secondary) => {
    document.documentElement.style.setProperty('--page-background', background);
    document.documentElement.style.setProperty('--page-primary', primary);
    document.documentElement.style.setProperty('--page-background-shaded', backgroundShaded);
    document.documentElement.style.setProperty('--page-primary-shaded', primaryShaded);
    document.documentElement.style.setProperty('--page-secondary', secondary);
  };
  