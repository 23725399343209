// graphicData.js

const graphicData = {
    "id": "NixTfE_ZSVNz32R0fIY4q",
    "type": "GRAPHIC",
    "name": "Untitled Design",
    "frame": {
        "width": 1200,
        "height": 1200
    },
    "scenes": [
        {
            "id": "HWRkiuKmHe5IYN6SZ1YPl",
            "layers": [
                {
                    "id": "TT8R_bk0g889B1UiO-UTB",
                    "name": "StaticImage",
                    "angle": 0,
                    "stroke": null,
                    "strokeWidth": 0,
                    "left": 211.82,
                    "top": 585.24,
                    "width": 1155,
                    "height": 830,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.66,
                    "scaleY": 0.66,
                    "type": "StaticImage",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "src": "https://publicpreview.awesomeheap.com/imagetest_6.svg",
                    "cropX": 0,
                    "cropY": 0,
                    "metadata": {}
                },
                {
                    "id": "3gfzP58He89jnBXlC4dNo",
                    "name": "StaticText",
                    "angle": -3.76,
                    "stroke": "#000000",
                    "strokeWidth": 0,
                    "left": 252.54,
                    "top": 290.71,
                    "width": 673.31,
                    "height": 145.83,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "bottom",
                    "scaleX": 1,
                    "scaleY": 1,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#d9d9d9",
                    "fontFamily": "Cookie-Regular",
                    "fontSize": 129.05036225409106,
                    "lineHeight": 0.6,
                    "text": "start today",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/cookie/v12/syky-y18lb0tSbfNlQCT9tPdpw.ttf",
                    "metadata": {}
                },
                {
                    "id": "9941Rqfh4nreFT8MYkrZz",
                    "name": "StaticText",
                    "angle": 0,
                    "stroke": "#ffffff",
                    "strokeWidth": 0,
                    "left": 200,
                    "top": 246.91,
                    "width": 800,
                    "height": 353.45,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 1,
                    "scaleY": 1,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#2a2b33",
                    "fontFamily": "Oswald-Regular",
                    "fontSize": 164.62648830731192,
                    "lineHeight": 0.9,
                    "text": "Cooking with passion",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf",
                    "metadata": {}
                },
                {
                    "id": "frameLayer",
                    "name": "RedFrame",
                    "angle": 0,
                    "stroke": "#ff0000",
                    "strokeWidth": 1,
                    "left": 0,
                    "top": 0,
                    "width": 1198, // уменьшили ширину на 1 пиксель
                    "height": 1198, // уменьшили высоту на 1 пиксель
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 1,
                    "scaleY": 1,
                    "type": "StaticRectangle",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "fill": null,
                    "metadata": {}
                }
            ],
            "name": "Untitled design"
        }
    ],
    "metadata": {},
    "preview": ""
  };
  
  export default graphicData;
  