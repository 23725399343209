import React from 'react';
import '../styles/LandingCategories.css'; // Подключаем CSS для анимаций

const LandingCategories = () => {
  const categories = [
    { src: 'https://publicpreview.awesomeheap.com/cyclist-athletes-are-riding-a-vp0oLW_medium.jpeg', label: 'Sport' },
    { src: 'https://publicpreview.awesomeheap.com/sunlight-filters-through-a-majestic-HCyfXE_medium.jpeg', label: 'Nature' },
    { src: 'https://publicpreview.awesomeheap.com/a-succulent-beef-steak-garnished-abD8w4_medium.jpeg', label: 'Food' },
    { src: 'https://publicpreview.awesomeheap.com/a-serious-businessman-in-a-5rqNa4_medium.jpeg', label: 'Business People' },
    { src: 'https://publicpreview.awesomeheap.com/a-group-of-friends-enjoy-FLsaCf_medium.jpeg', label: 'Summer' }
  ];

  const handleClick = (label) => {
    const formattedLabel = encodeURIComponent(label).replace(/%20/g, '-');
    window.location.href = `/search/${formattedLabel}`;
  };

  return (
    <div className="landing-container">
      {categories.map((category, index) => (
        <div
          key={index}
          className="landing-category"
          style={{ backgroundImage: `url(${category.src})` }}
          onClick={() => handleClick(category.label)}
        >
          <h2>{category.label}</h2>
        </div>
      ))}
    </div>
  );
};

export default LandingCategories;
