// graphicData.js

const graphicData = {
    "id": "NixTfE_ZSVNz32R0fIY4q",
    "type": "GRAPHIC",
    "name": "YouTube Cover Design",
    "frame": {
        "width": 2560,
        "height": 1440
    },
    "scenes": [
        // Existing Scenes
        {
            "id": "HWRkiuKmHe5IYN6SZ1YPl",
            "name": "YouTube Cover - Scene 1",
            "layers": [
                // ... (existing layers)
            ]
        },
        {
            "id": "Scene2_Id_Unique",
            "name": "YouTube Cover - Scene 2",
            "layers": [
                // ... (existing layers)
            ]
        },
        {
            "id": "Scene3_Id_Unique",
            "name": "YouTube Cover - Scene 3",
            "layers": [
                // ... (existing layers)
            ]
        },
        // New Scenes
        {
            "id": "Scene4_Id_Unique",
            "name": "YouTube Cover - Scene 4",
            "layers": [
                {
                    "id": "TT8R_bk0g889B1UiO-UTB_4",
                    "name": "StaticImage",
                    "angle": 0,
                    "stroke": null,
                    "strokeWidth": 0,
                    "left": 920.13, // Slightly adjusted position
                    "top": 640.64,
                    "width": 1155,
                    "height": 830,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.60, // Slightly reduced scale
                    "scaleY": 0.60,
                    "type": "StaticImage",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "src": "https://publicpreview.awesomeheap.com/imagetest_6.svg",
                    "cropX": 0,
                    "cropY": 0,
                    "metadata": {}
                },
                {
                    "id": "3gfzP58He89jnBXlC4dNo_4",
                    "name": "StaticText",
                    "angle": -2.5, // Slightly adjusted angle
                    "stroke": "#000000",
                    "strokeWidth": 0,
                    "left": 960.54,
                    "top": 360.48,
                    "width": 673.31,
                    "height": 145.83,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "bottom",
                    "scaleX": 0.94,
                    "scaleY": 0.94,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#FFA07A", // Light salmon color
                    "fontFamily": "Cookie-Regular",
                    "fontSize": 130.00,
                    "lineHeight": 0.6,
                    "text": "start today",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/cookie/v12/syky-y18lb0tSbfNlQCT9tPdpw.ttf",
                    "metadata": {}
                },
                {
                    "id": "9941Rqfh4nreFT8MYkrZz_4",
                    "name": "StaticText",
                    "angle": 0,
                    "stroke": "#ffffff",
                    "strokeWidth": 0,
                    "left": 910,
                    "top": 320.03,
                    "width": 800,
                    "height": 350.45,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.94,
                    "scaleY": 0.94,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#FF6347", // Tomato color
                    "fontFamily": "Oswald-Regular",
                    "fontSize": 160.00,
                    "lineHeight": 0.9,
                    "text": "Cooking with passion",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf",
                    "metadata": {}
                },
                {
                    "id": "frameLayer_4",
                    "name": "OrangeFrame",
                    "angle": 0,
                    "stroke": "#FFA500", // Orange color
                    "strokeWidth": 1,
                    "left": 0,
                    "top": 0,
                    "width": 2560,
                    "height": 1440,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 1,
                    "scaleY": 1,
                    "type": "StaticRectangle",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "fill": null,
                    "metadata": {}
                }
            ]
        },
        {
            "id": "Scene5_Id_Unique",
            "name": "YouTube Cover - Scene 5",
            "layers": [
                {
                    "id": "TT8R_bk0g889B1UiO-UTB_5",
                    "name": "StaticImage",
                    "angle": 0,
                    "stroke": null,
                    "strokeWidth": 0,
                    "left": 900.13,
                    "top": 630.64,
                    "width": 1155,
                    "height": 830,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.65,
                    "scaleY": 0.65,
                    "type": "StaticImage",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "src": "https://publicpreview.awesomeheap.com/imagetest_6.svg",
                    "cropX": 0,
                    "cropY": 0,
                    "metadata": {}
                },
                {
                    "id": "3gfzP58He89jnBXlC4dNo_5",
                    "name": "StaticText",
                    "angle": -4.0,
                    "stroke": "#000000",
                    "strokeWidth": 0,
                    "left": 940.54,
                    "top": 355.48,
                    "width": 673.31,
                    "height": 145.83,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "bottom",
                    "scaleX": 0.98,
                    "scaleY": 0.98,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#DA70D6", // Orchid color
                    "fontFamily": "Cookie-Regular",
                    "fontSize": 128.00,
                    "lineHeight": 0.6,
                    "text": "start today",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/cookie/v12/syky-y18lb0tSbfNlQCT9tPdpw.ttf",
                    "metadata": {}
                },
                {
                    "id": "9941Rqfh4nreFT8MYkrZz_5",
                    "name": "StaticText",
                    "angle": 0,
                    "stroke": "#ffffff",
                    "strokeWidth": 0,
                    "left": 890,
                    "top": 305.03,
                    "width": 800,
                    "height": 355.45,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.98,
                    "scaleY": 0.98,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#8A2BE2", // Blue violet color
                    "fontFamily": "Oswald-Regular",
                    "fontSize": 165.00,
                    "lineHeight": 0.9,
                    "text": "Cooking with passion",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf",
                    "metadata": {}
                },
                {
                    "id": "frameLayer_5",
                    "name": "PurpleFrame",
                    "angle": 0,
                    "stroke": "#DDA0DD", // Plum color
                    "strokeWidth": 1,
                    "left": 0,
                    "top": 0,
                    "width": 2560,
                    "height": 1440,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 1,
                    "scaleY": 1,
                    "type": "StaticRectangle",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "fill": null,
                    "metadata": {}
                }
            ]
        },
        {
            "id": "Scene6_Id_Unique",
            "name": "YouTube Cover - Scene 6",
            "layers": [
                {
                    "id": "TT8R_bk0g889B1UiO-UTB_6",
                    "name": "StaticImage",
                    "angle": 0,
                    "stroke": null,
                    "strokeWidth": 0,
                    "left": 915.13,
                    "top": 635.64,
                    "width": 1155,
                    "height": 830,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.62,
                    "scaleY": 0.62,
                    "type": "StaticImage",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "src": "https://publicpreview.awesomeheap.com/imagetest_6.svg",
                    "cropX": 0,
                    "cropY": 0,
                    "metadata": {}
                },
                {
                    "id": "3gfzP58He89jnBXlC4dNo_6",
                    "name": "StaticText",
                    "angle": -3.0,
                    "stroke": "#000000",
                    "strokeWidth": 0,
                    "left": 950.54,
                    "top": 358.48,
                    "width": 673.31,
                    "height": 145.83,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "bottom",
                    "scaleX": 0.95,
                    "scaleY": 0.95,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#FFD700", // Gold color
                    "fontFamily": "Cookie-Regular",
                    "fontSize": 129.50,
                    "lineHeight": 0.6,
                    "text": "start today",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/cookie/v12/syky-y18lb0tSbfNlQCT9tPdpw.ttf",
                    "metadata": {}
                },
                {
                    "id": "9941Rqfh4nreFT8MYkrZz_6",
                    "name": "StaticText",
                    "angle": 0,
                    "stroke": "#ffffff",
                    "strokeWidth": 0,
                    "left": 905,
                    "top": 312.03,
                    "width": 800,
                    "height": 352.45,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.95,
                    "scaleY": 0.95,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#FFD700", // Gold color
                    "fontFamily": "Oswald-Regular",
                    "fontSize": 163.00,
                    "lineHeight": 0.9,
                    "text": "Cooking with passion",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf",
                    "metadata": {}
                },
                {
                    "id": "frameLayer_6",
                    "name": "GoldFrame",
                    "angle": 0,
                    "stroke": "#EEE8AA", // Pale goldenrod color
                    "strokeWidth": 1,
                    "left": 0,
                    "top": 0,
                    "width": 2560,
                    "height": 1440,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 1,
                    "scaleY": 1,
                    "type": "StaticRectangle",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "fill": null,
                    "metadata": {}
                }
            ]
        },
        {
            "id": "Scene7_Id_Unique",
            "name": "YouTube Cover - Scene 7",
            "layers": [
                {
                    "id": "TT8R_bk0g889B1UiO-UTB_7",
                    "name": "StaticImage",
                    "angle": 0,
                    "stroke": null,
                    "strokeWidth": 0,
                    "left": 905.13,
                    "top": 628.64,
                    "width": 1155,
                    "height": 830,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.64,
                    "scaleY": 0.64,
                    "type": "StaticImage",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "src": "https://publicpreview.awesomeheap.com/imagetest_6.svg",
                    "cropX": 0,
                    "cropY": 0,
                    "metadata": {}
                },
                {
                    "id": "3gfzP58He89jnBXlC4dNo_7",
                    "name": "StaticText",
                    "angle": -3.5,
                    "stroke": "#000000",
                    "strokeWidth": 0,
                    "left": 945.54,
                    "top": 354.48,
                    "width": 673.31,
                    "height": 145.83,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "bottom",
                    "scaleX": 0.97,
                    "scaleY": 0.97,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#FF1493", // Deep pink color
                    "fontFamily": "Cookie-Regular",
                    "fontSize": 129.20,
                    "lineHeight": 0.6,
                    "text": "start today",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/cookie/v12/syky-y18lb0tSbfNlQCT9tPdpw.ttf",
                    "metadata": {}
                },
                {
                    "id": "9941Rqfh4nreFT8MYkrZz_7",
                    "name": "StaticText",
                    "angle": 0,
                    "stroke": "#ffffff",
                    "strokeWidth": 0,
                    "left": 895,
                    "top": 308.03,
                    "width": 800,
                    "height": 354.45,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.97,
                    "scaleY": 0.97,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#C71585", // Medium violet red
                    "fontFamily": "Oswald-Regular",
                    "fontSize": 164.00,
                    "lineHeight": 0.9,
                    "text": "Cooking with passion",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf",
                    "metadata": {}
                },
                {
                    "id": "frameLayer_7",
                    "name": "PinkFrame",
                    "angle": 0,
                    "stroke": "#FFB6C1", // Light pink color
                    "strokeWidth": 1,
                    "left": 0,
                    "top": 0,
                    "width": 2560,
                    "height": 1440,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 1,
                    "scaleY": 1,
                    "type": "StaticRectangle",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "fill": null,
                    "metadata": {}
                }
            ]
        },
        {
            "id": "Scene8_Id_Unique",
            "name": "YouTube Cover - Scene 8",
            "layers": [
                {
                    "id": "TT8R_bk0g889B1UiO-UTB_8",
                    "name": "StaticImage",
                    "angle": 0,
                    "stroke": null,
                    "strokeWidth": 0,
                    "left": 910.13,
                    "top": 632.64,
                    "width": 1155,
                    "height": 830,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.61,
                    "scaleY": 0.61,
                    "type": "StaticImage",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "src": "https://publicpreview.awesomeheap.com/imagetest_6.svg",
                    "cropX": 0,
                    "cropY": 0,
                    "metadata": {}
                },
                {
                    "id": "3gfzP58He89jnBXlC4dNo_8",
                    "name": "StaticText",
                    "angle": -2.0,
                    "stroke": "#000000",
                    "strokeWidth": 0,
                    "left": 955.54,
                    "top": 362.48,
                    "width": 673.31,
                    "height": 145.83,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "bottom",
                    "scaleX": 0.93,
                    "scaleY": 0.93,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#00CED1", // Dark turquoise
                    "fontFamily": "Cookie-Regular",
                    "fontSize": 130.50,
                    "lineHeight": 0.6,
                    "text": "start today",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/cookie/v12/syky-y18lb0tSbfNlQCT9tPdpw.ttf",
                    "metadata": {}
                },
                {
                    "id": "9941Rqfh4nreFT8MYkrZz_8",
                    "name": "StaticText",
                    "angle": 0,
                    "stroke": "#ffffff",
                    "strokeWidth": 0,
                    "left": 900,
                    "top": 310.03,
                    "width": 800,
                    "height": 351.45,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 0.93,
                    "scaleY": 0.93,
                    "type": "StaticText",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "charSpacing": -20,
                    "fill": "#20B2AA", // Light sea green
                    "fontFamily": "Oswald-Regular",
                    "fontSize": 162.50,
                    "lineHeight": 0.9,
                    "text": "Cooking with passion",
                    "textAlign": "center",
                    "fontURL": "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf",
                    "metadata": {}
                },
                {
                    "id": "frameLayer_8",
                    "name": "TurquoiseFrame",
                    "angle": 0,
                    "stroke": "#AFEEEE", // Pale turquoise
                    "strokeWidth": 1,
                    "left": 0,
                    "top": 0,
                    "width": 2560,
                    "height": 1440,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "scaleX": 1,
                    "scaleY": 1,
                    "type": "StaticRectangle",
                    "flipX": false,
                    "flipY": false,
                    "skewX": 0,
                    "skewY": 0,
                    "visible": true,
                    "shadow": null,
                    "fill": null,
                    "metadata": {}
                }
            ]
        }
    ],
    "metadata": {},
    "preview": ""
};

export default graphicData;
