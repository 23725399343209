import React, { useEffect } from 'react';
import useColorStore from '../../store/colorStore'; // Импортируем цветовой store
import '../../styles/ColorSetPreview.css'; // Импортируем CSS-файл

const ColorSetPreview = ({ colors, isActive, setName, setActiveSetName }) => {
  const setColors = useColorStore((state) => state.setColors); // Получаем функцию для обновления цветов

  const colorKeys = ['a', 'b', 'c', 'd'];

  // Применение первой гаммы цветов при монтировании компонента
  useEffect(() => {
    if (isActive) {
      setColors(colors); // Применение цветовой схемы при первой загрузке
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]); // Зависимость от активности текущего набора

  const handleClick = () => {
    setColors(colors); // Применение цветовой схемы из набора
    setActiveSetName(setName); // Устанавливаем активный набор
  };

  return (
    <div
      onClick={handleClick}
      className={`color-set-preview ${isActive ? 'active' : ''}`}
      title="Click to apply this color set"
    >
      {/* Добавляем белый блок под первым цветом */}
      <div className="white-background" />
      {colorKeys.map((key, index) => {
        const isFirst = index === 0;
        const isLast = index === colorKeys.length - 1;
        const blockClass = isFirst
          ? 'color-block first'
          : isLast
          ? 'color-block last'
          : 'color-block';

        return (
          <div
            key={key}
            className={blockClass}
            style={{ backgroundColor: colors[key] }}
          />
        );
      })}
    </div>
  );
};

export default ColorSetPreview;
