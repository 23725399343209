// TemplateDetailComponent.jsx

import React, { useRef } from 'react';

// Импортируйте данные графики из отдельного файла
import graphicData from './TstgraphicData2.jsx'; 

// Импортируем TemplateViewer из отдельного файла
import TemplateViewer from './Template_viewer.jsx';

/**
 * Основной компонент, отображающий детали шаблона
 */
const TemplateDetailComponent = () => {
  const viewerRef = useRef(null);

  return (
    <div style={styles.mainContainer}>
      <GraphicDetailLayout graphicData={graphicData} viewerRef={viewerRef} />
    </div>
  );
};

export default TemplateDetailComponent;

/**
 * Компонент для соответствия желаемому макету
 * @param {object} props - Свойства компонента
 * @param {object} props.graphicData - Данные графики
 * @param {object} props.viewerRef - Реф для TemplateViewer
 */
const GraphicDetailLayout = ({ graphicData, viewerRef }) => {
  // Извлекаем ширину и высоту для расчета соотношения сторон
  const width = graphicData.frame?.width || 800;
  const height = graphicData.frame?.height || 600;
  const aspectRatio = width && height ? width / height : 16 / 9; // По умолчанию 16:9

  return (
    <div style={styles.container}>
     
      <div style={styles.leftColumn}>
        <TemplateViewer graphicData={graphicData} aspectRatio={aspectRatio} />
      </div>
    </div>
  );
};

/**
 * Объект с стилями для компонентов
 */
const styles = {
  mainContainer: {

    boxSizing: 'border-box',
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '20px',
  },
  leftColumn: {
    flex: '1 1 300px',

    boxSizing: 'border-box',
    width: '100%',
  },
  rightColumn: {
    flex: '1 1 300px',

    boxSizing: 'border-box',
    width: '100%',
  },
  infoContainer: {
    padding: '10px',
    boxSizing: 'border-box',
  },
};
