// App.jsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import useColorStore from '../store/colorStore';
import HomePage from './HomePage.jsx';
import PricingPage from './PricingPage.jsx';
import LoginPage from './LoginPage.jsx';
import PhotoDetailPage from './PhotoDetailPage.jsx';
import TermsPage from './TermsPage.jsx';
import PrivacyPage from './PrivacyPage.jsx';
import SearchPage from './SearchPage.jsx';
import IconDetailPage from './IconDetailPage.jsx';
import TemplateDetailPage from './TemplateDetailPage.jsx';
import CollectionPage from './CollectionPage.jsx';
import RatingToolPage from './RatingToolPage.jsx'; // Импортируем новую страницу
import MakeMeCoverPage from './MakeMeCoverPage.jsx'; // Импортируем новую страницу
import '../styles/font.css';
import posthog from 'posthog-js'

posthog.init('phc_czZCgIeU9rdqy0RTNvSc0OCrKDD1JzcdyEsc9qg2KNA',
    {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
    }
)

function App() {
  const { background, primary, backgroundShaded, primaryShaded, secondary } = useColorStore(state => state.pageColors);

  useEffect(() => {
    // Установка глобальных CSS переменных для цветов
    document.documentElement.style.setProperty('--page-background', background);
    document.documentElement.style.setProperty('--page-primary', primary);
    document.documentElement.style.setProperty('--page-background-shaded', backgroundShaded);
    document.documentElement.style.setProperty('--page-primary-shaded', primaryShaded);
    document.documentElement.style.setProperty('--page-secondary', secondary);
  }, [background, primary, backgroundShaded, primaryShaded, secondary]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/prices" element={<PricingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/photo/:slug" element={<PhotoDetailPage />} />
        <Route path="/icon/:slug" element={<IconDetailPage />} />
        <Route path="/template/:slug" element={<TemplateDetailPage />} />
        <Route path="/search/:query" element={<SearchPage />} />
        <Route path="/collection/:collection" element={<CollectionPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/rating-tool" element={<RatingToolPage />} /> {/* Добавляем этот маршрут */}
        <Route path="/makemecover" element={<MakeMeCoverPage />} />
      </Routes>
    </Router>
  );
}

export default App;
