import React, { useEffect, useState } from 'react';
import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

export default function Gallery({ photos }) {
    const [targetRowHeight, setTargetRowHeight] = useState(230);

    useEffect(() => {
        // Function to update targetRowHeight based on window width
        const updateRowHeight = () => {
            if (window.innerWidth <= 768) { // Define your mobile breakpoint here
                setTargetRowHeight(120);
            } else {
                setTargetRowHeight(200);
            }
        };

        // Initial check
        updateRowHeight();

        // Add event listener for window resize
        window.addEventListener('resize', updateRowHeight);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', updateRowHeight);
    }, []);

    useEffect(() => {
        // console.log('Photos:', photos);
    }, [photos]);

    if (!photos.length) {
        return null;
    }

    const handleClick = ({ index }) => {
        const photo = photos[index];
        if (photo && photo.url) {
            // Действие при клике на фото
        }
    };

    return (
        <RowsPhotoAlbum 
            photos={photos} 
            onClick={handleClick}
            targetRowHeight={targetRowHeight}
            rowConstraints={{ singleRowMaxHeight: 240 }}
            spacing={7}
            render={{
                extras: (_, { photo, index }) => {
                    const { isFree, url, mediaType, combinedScore, rating, similarity } = photo;
                    return (
                        <>
                            {!isFree && (
                                <img 
                                    src="/images/crown.svg" 
                                    alt="Premium" 
                                    style={{
                                        position: 'absolute',
                                        top: 10,
                                        left: 10,
                                        width: '20px',
                                        height: '20px',
                                        zIndex: 10
                                    }}
                                />
                            )}
                            {url && (
                                <a 
                                    href={url} 
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}
                                />
                            )}
                            {mediaType === 'icon' && (
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    boxSizing: 'border-box',
                                    pointerEvents: 'none',
                                    zIndex: 2
                                }} />
                            )}
                            {/* FOR DEBUG. DO NOT REMOVE Добавляем отображение оценок под изображением */}
                             {/* когда включено не работает детальная страница */}
                            {/* <div style={{
                                position: 'absolute',
                                bottom: 5,
                                left: 5,
                                color: 'white',
                                zIndex: 10,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                padding: '2px 5px',
                                borderRadius: '4px',
                                fontSize: '12px'
                            }}>
                                Rating: {rating} | Sim: {similarity.toFixed(2)} | Score: {combinedScore.toFixed(2)}
                            </div> */}
                        </>
                    );
                },
            }}
        />
    );
}
