import { create } from 'zustand';
import {
  getAuth,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  onSnapshot,
  collection,
  getDocs,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from './config/firebaseConfig'; // Уточните путь при необходимости

export const useUserStore = create((set, get) => ({
  user: null,
  userId: null,
  email: null, // Добавили свойство email
  avatarUrl: null,
  portalLink: null,
  subscriptionEnergy: null,
  permanentEnergy: null,
  subscriptions: [],
  loadingSubscriptions: true,
  hasActiveSubscription: false,
  subscriptionError: null,

  setUser: (user) => {
    set({
      user,
      userId: user ? user.uid : null,
      email: user ? user.email : null, // Устанавливаем email
      loadingSubscriptions: true,
      subscriptionError: null,
      subscriptions: [],
    });
  },

  initializeUser: () => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      get().setUser(user);
      if (user) {
        set({ avatarUrl: user.photoURL, email: user.email }); // Устанавливаем email
        get().fetchPortalLink();
        get().fetchSubscriptionEnergy();
        get().fetchSubscriptions(user.uid);
      } else {
        set({
          avatarUrl: null,
          email: null, // Сбрасываем email
          portalLink: null,
          subscriptionEnergy: null,
          permanentEnergy: null,
          loadingSubscriptions: false,
        });
      }
    });
    return unsubscribe;
  },

  fetchSubscriptions: async (userId) => {
    const firestore = getFirestore(app);
    const subscriptionsRef = collection(
      firestore,
      'users',
      userId,
      'subscriptions'
    );
    try {
      const subscriptionsSnapshot = await getDocs(subscriptionsRef);
      const subscriptionsList = subscriptionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const activeSubscription = subscriptionsList.some(
        (subscription) => subscription.status === 'active'
      );
      set({
        subscriptions: subscriptionsList,
        loadingSubscriptions: false,
        hasActiveSubscription: activeSubscription,
      });
    } catch (err) {
      console.error('Ошибка при получении подписок:', err);
      set({
        subscriptionError: `Error fetching subscriptions: ${err.message}`,
        loadingSubscriptions: false,
      });
    }
  },

  fetchSubscriptionEnergy: () => {
    const user = get().user;
    if (!user) {
      console.error('Пользователь не аутентифицирован');
      set({ loadingSubscriptions: false });
      return;
    }
    const userDocRef = doc(getFirestore(app), 'users', user.uid);
    const unsubscribe = onSnapshot(
      userDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          set({
            subscriptionEnergy: data.subscription_energy || 0,
            permanentEnergy: data.permanent_energy || 0,
          });
        } else {
          console.error('Документ не найден!');
        }
      },
      (error) => {
        console.error('Ошибка при получении документа:', error);
        set({ loadingSubscriptions: false });
      }
    );
    return unsubscribe;
  },

  loginUser: async () => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      get().setUser(result.user);
      set({
        avatarUrl: result.user.photoURL,
        email: result.user.email, // Устанавливаем email
      });
      get().fetchPortalLink();
      get().fetchSubscriptionEnergy();
      get().fetchSubscriptions(result.user.uid);
    } catch (error) {
      console.error('Ошибка при входе в систему:', error);
      set({ loadingSubscriptions: false });
    }
  },

  logoutUser: () => {
    const auth = getAuth(app);
    signOut(auth)
      .then(() => {
        get().setUser(null);
        set({
          avatarUrl: null,
          email: null, // Сбрасываем email
          portalLink: null,
          subscriptionEnergy: null,
          permanentEnergy: null,
          subscriptions: [],
        });
      })
      .catch((error) => {
        console.error('Ошибка при выходе из системы:', error);
      });
  },

  fetchPortalLink: async () => {
    const user = get().user;
    if (!user) {
      console.error('Пользователь не аутентифицирован');
      return;
    }
    const functions = getFunctions(app, 'us-central1');
    const functionRef = httpsCallable(
      functions,
      'ext-firestore-stripe-payments-createPortalLink'
    );
    try {
      const { data } = await functionRef({
        customerId: user.uid,
        returnUrl: window.location.origin,
      });
      set({ portalLink: data.url });
    } catch (error) {
      console.error('Ошибка при получении URL портала:', error);
    }
  },
}));
