// src/components/Logo.jsx
import React from 'react';
import useColorStore from '../store/colorStore';

const Logo = () => {
  const PagePrimary = useColorStore((state) => state.pageColors.primary);

  const styles = {
    logosvgContainer: {
      width: '65px',      
      height: 'auto',       
      maxWidth: '100%',    
      maxHeight: '100px',  
    },
    logosvg: {
      width: '100%',
      height: '100%',
    },
  };

  return (
    <div style={styles.logosvgContainer}>
      <svg
        style={styles.logosvgsvg}
        version="1.2"
        baseProfile="tiny"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 100 61.53"
        overflow="visible"
        xmlSpace="preserve"
      >
        <path
          fill={PagePrimary}
          d="M11.95,58.48H5.46l-1.04,3.06h-4.43l6.29-17.31h4.9l6.29,17.31h-4.48L11.95,58.48z M10.86,55.22l-2.15-6.34
          	l-2.13,6.34H10.86z"
        />
        <path
          fill={PagePrimary}
          d="M24.83,19.84l5.97,16.88l-4.25,2.86l-8.7-7.9l4.02,11.04l-4.25,2.86L4.24,33.68L8,31.16l9.15,9.05
          	l-4.52-12.17l3.86-2.6l9.54,8.79l-4.99-11.85L24.83,19.84z"
        />
        <path
          fill={PagePrimary}
          d="M27,8.14l2.14,2.78l4.5-3.44l1.99,2.58l-4.5,3.44l2.32,3.01l5.09-3.89l2.07,2.68l-8.44,6.46L21.58,8.04
          	l8.44-6.46l2.07,2.68L27,8.14z"
        />
        <path
          fill={PagePrimary}
          d="M41.86,38.94c-1.08-0.16-2-0.57-2.78-1.21c-0.78-0.64-1.31-1.51-1.61-2.61l4.38-1.05
          	c0.21,0.61,0.55,1.03,0.99,1.27c0.45,0.24,0.97,0.29,1.56,0.15c0.61-0.15,1.06-0.4,1.34-0.77c0.29-0.36,0.37-0.79,0.25-1.29
          	c-0.1-0.42-0.32-0.72-0.67-0.93c-0.35-0.2-0.75-0.35-1.2-0.42c-0.45-0.08-1.08-0.14-1.89-0.18c-1.17-0.07-2.15-0.2-2.92-0.37
          	c-0.77-0.17-1.48-0.52-2.14-1.06c-0.66-0.54-1.11-1.33-1.37-2.39c-0.38-1.57-0.1-2.93,0.82-4.09c0.93-1.16,2.3-1.96,4.13-2.4
          	c1.86-0.45,3.47-0.36,4.82,0.25c1.35,0.61,2.26,1.7,2.72,3.27l-4.45,1.07c-0.16-0.54-0.47-0.92-0.91-1.14
          	c-0.44-0.22-0.96-0.27-1.53-0.13c-0.5,0.12-0.87,0.35-1.11,0.68c-0.24,0.34-0.3,0.75-0.18,1.25c0.13,0.54,0.49,0.91,1.08,1.09
          	c0.59,0.18,1.47,0.32,2.64,0.41c1.18,0.11,2.15,0.25,2.92,0.42c0.76,0.17,1.47,0.52,2.12,1.04c0.65,0.52,1.1,1.28,1.34,2.29
          	c0.23,0.96,0.2,1.89-0.1,2.79c-0.3,0.9-0.86,1.69-1.68,2.38c-0.82,0.69-1.86,1.18-3.13,1.49C44.1,39.04,42.94,39.11,41.86,38.94z"
        />
        <path
          fill={PagePrimary}
          d="M52.45,17.44c-1.5-0.43-2.79-1.22-3.88-2.36c-1.09-1.14-1.82-2.54-2.21-4.19c-0.38-1.65-0.34-3.22,0.14-4.72
          	s1.29-2.76,2.44-3.81c1.16-1.04,2.53-1.75,4.12-2.12c1.59-0.37,3.14-0.34,4.64,0.09c1.5,0.43,2.78,1.22,3.85,2.35
          	s1.8,2.53,2.18,4.18s0.34,3.23-0.12,4.72c-0.46,1.5-1.27,2.77-2.42,3.81c-1.15,1.04-2.52,1.75-4.11,2.11
          	C55.49,17.9,53.95,17.87,52.45,17.44z M59.14,11.69c0.6-1.08,0.73-2.36,0.39-3.84c-0.35-1.49-1.03-2.59-2.05-3.29
          	s-2.2-0.9-3.56-0.58c-1.37,0.32-2.35,1.01-2.96,2.08c-0.61,1.07-0.74,2.36-0.39,3.86c0.35,1.49,1.03,2.59,2.05,3.29
          	s2.21,0.89,3.58,0.58C57.56,13.47,58.54,12.77,59.14,11.69z"
        />
        <path
          fill={PagePrimary}
          d="M71.59,15.69l7.94,15.4l-3.77,1.93L71,23.78l1.3,11.01l-3.04,1.56l-8.25-7.48l4.77,9.26l-3.77,1.93
          	l-7.94-15.4l4.45-2.28l9.84,8.44l-1.2-12.86L71.59,15.69z"
        />
        <path
          fill={PagePrimary}
          d="M87.03,23.7l1.9,2.94l4.77-3.06l1.77,2.74l-4.77,3.06l2.07,3.19l5.39-3.46l1.84,2.84l-8.95,5.74l-9.41-14.56
          	l8.95-5.74l1.84,2.84L87.03,23.7z"
        />
        <path
          fill={PagePrimary}
          d="M46.7,44.22v17.31h-4.24v-7.13h-6.59v7.13h-4.24V44.22h4.24V51h6.59v-6.78H46.7z"
        />
        <path
          fill={PagePrimary}
          d="M53.98,47.6v3.5h5.67v3.26h-5.67v3.8h6.41v3.38H49.75V44.22H60.4v3.38H53.98z"
        />
        <path
          fill={PagePrimary}
          d="M73.97,58.48h-6.49l-1.04,3.06h-4.43l6.29-17.31h4.9l6.29,17.31h-4.48L73.97,58.48z M72.88,55.22l-2.15-6.34
          	l-2.13,6.34H72.88z"
        />
        <path
          fill={PagePrimary}
          d="M93.95,52.54c-0.46,0.83-1.17,1.5-2.13,2.01c-0.96,0.51-2.15,0.76-3.57,0.76h-2.62v6.22H81.4V44.22h6.86
          	c1.39,0,2.56,0.24,3.52,0.72c0.96,0.48,1.68,1.13,2.15,1.97c0.48,0.84,0.72,1.8,0.72,2.89C94.65,50.8,94.42,51.71,93.95,52.54z
          	 M89.74,51.4c0.4-0.38,0.59-0.91,0.59-1.6s-0.2-1.23-0.59-1.6c-0.4-0.38-1-0.57-1.81-0.57h-2.3v4.34h2.3
          	C88.74,51.96,89.35,51.78,89.74,51.4z"
        />
      </svg>
    </div>
  );
};

export default Logo;