// src/pages/RatingToolPage.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { RowsPhotoAlbum } from 'react-photo-album';
import 'react-photo-album/rows.css';
import '../styles/RatingToolPage.css';

const RatingToolPage = () => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoveredPhoto, setHoveredPhoto] = useState(null);

  // Array to store indices of bad photos
  const [badPhotos, setBadPhotos] = useState([]);

  // Array to store indices of selected photos (framed)
  const [selectedPhotos, setSelectedPhotos] = useState([]);

  // State to track if 'D' key is pressed
  const [isDPressed, setIsDPressed] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACK_URL || 'http://localhost:8000';
  const ENDPOINT = `${BACKEND_URL}/rating-tool`;

  // Fetch assets from the backend
  const fetchAssets = async () => {
    try {
      const response = await fetch(ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'fetch_assets' }),
      });
      if (!response.ok) {
        throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      setAssets(data);
    } catch (err) {
      console.error('Не удалось загрузить ассеты:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  // Handle keydown events
  const handleKeyDown = useCallback(
    (event) => {
      if (event.code === 'KeyD') {
        if (!isDPressed) {
          setIsDPressed(true);
          // If a photo is currently hovered, darken it immediately
          if (hoveredPhoto !== null && !badPhotos.includes(hoveredPhoto)) {
            setBadPhotos((prevBad) => [...prevBad, hoveredPhoto]);
            // Remove from selectedPhotos if it's selected
            setSelectedPhotos((prevSelected) =>
              prevSelected.filter((i) => i !== hoveredPhoto)
            );
          }
        }
      } else if (event.code === 'KeyS') {
        // Toggle frame on the currently hovered photo
        if (hoveredPhoto !== null) {
          setSelectedPhotos((prevSelected) => {
            if (prevSelected.includes(hoveredPhoto)) {
              // If already selected, remove the frame
              return prevSelected.filter((i) => i !== hoveredPhoto);
            } else {
              // If not selected, add the frame
              return [...prevSelected, hoveredPhoto];
            }
          });

          // Ensure the photo is not marked as bad when selected
          setBadPhotos((prevBad) => prevBad.filter((i) => i !== hoveredPhoto));
        }
      }
    },
    [isDPressed, hoveredPhoto, badPhotos]
  );

  // Handle keyup events
  const handleKeyUp = useCallback((event) => {
    if (event.code === 'KeyD') {
      setIsDPressed(false);
    }
    // No need to handle 'KeyS' here since we don't track its state anymore
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  // Handle mouse enter when 'D' is pressed
  const handleMouseEnter = useCallback(
    (index) => {
      setHoveredPhoto(index);
      if (isDPressed && !badPhotos.includes(index)) {
        setBadPhotos((prevBad) => [...prevBad, index]);
        // Remove from selectedPhotos if it's selected
        setSelectedPhotos((prevSelected) =>
          prevSelected.filter((i) => i !== index)
        );
      }
      // You can add immediate framing if desired when 'S' is pressed
      // Since 'S' no longer needs to be pressed to frame, this part is removed
    },
    [isDPressed, badPhotos]
  );

  // Handle mouse leave
  const handleMouseLeave = useCallback(() => {
    setHoveredPhoto(null);
  }, []);

  // Handle click to toggle bad photo
  const handleClick = useCallback(
    (index) => {
      // Only handle toggling bad photos on click
      setBadPhotos((prevBad) => {
        if (prevBad.includes(index)) {
          // If already bad, remove it to lighten the photo
          return prevBad.filter((i) => i !== index);
        } else {
          // If not bad, add it to darken the photo
          const newBadPhotos = [...prevBad, index];
          // Remove from selectedPhotos if it's selected
          setSelectedPhotos((prevSelected) =>
            prevSelected.filter((i) => i !== index)
          );
          return newBadPhotos;
        }
      });
    },
    []
  );

  if (loading) {
    return <div>Загрузка ассетов...</div>;
  }

  if (error) {
    return <div>Ошибка при загрузке ассетов: {error}</div>;
  }

  // Map assets to photos format required by react-photo-album
  const photos = assets.map((asset, index) => ({
    src: asset.url_small || asset.url,
    width: asset.url_small_width || 100,
    height: asset.url_small_height || 100,
    alt: asset.title || 'Asset Image',
    loading: 'lazy',
    key: asset.asset_id,
    url: `/asset/${asset.asset_id}`,
    isFree: asset.is_free || false,
    mediaType: asset.media_type || 'image',
    index, // Use index for identification
  }));

  return (
    <div
      className="rating-tool-page"
      tabIndex="0" // Allows the component to receive focus
      style={{ outline: 'none' }} // Removes the default focus outline
    >
      {photos.length === 0 ? (
        <p>Ассеты не найдены.</p>
      ) : (
        <RowsPhotoAlbum
          photos={photos}
          targetRowHeight={240}
          rowConstraints={{ singleRowMaxHeight: 400 }}
          spacing={7}
          render={{
            extras: (photoProps, { photo }) => {
              const { url, mediaType, index } = photo;
              const isBad = badPhotos.includes(index);
              const isSelected = selectedPhotos.includes(index);

              return (
                <>
                  {url && (
                    <a
                      href={url}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                      }}
                      target="_blank" // Open in a new tab
                      rel="noopener noreferrer"
                    />
                  )}
                  {mediaType === 'icon' && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: '1px solid #ccc',
                        borderRadius: '6px',
                        boxSizing: 'border-box',
                        pointerEvents: 'none',
                        zIndex: 2,
                      }}
                    />
                  )}
                  {/* Dark overlay for bad images, only if not selected */}
                  {isBad && !isSelected && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)', // 80% затемнение
                        zIndex: 5,
                      }}
                    />
                  )}
                  {/* Frame for selected images */}
                  {isSelected && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: '4px solid #fff', // Белая рамка
                        boxSizing: 'border-box',
                        zIndex: 6,
                      }}
                    />
                  )}
                  {/* Invisible layer to handle hover and click */}
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: 10,
                      cursor: isDPressed ? 'crosshair' : 'pointer',
                    }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleClick(index)}
                  />
                </>
              );
            },
          }}
        />
      )}
      {/* Optional: Visual indicator for 'D' key pressed */}
      {isDPressed && (
        <div className="d-key-indicator">
          'D' key is pressed. Hover to darken images.
        </div>
      )}
      {/* Removed the 'S' key indicator as it's no longer needed */}
    </div>
  );
};

export default RatingToolPage;