// LoginPage.jsx
import React from 'react';
import BaseLayout from '../layouts/BaseLayout.jsx';
import LoginC from '../components/Login.jsx';

function LoginPage() {
  return (
    <BaseLayout
      title="Login to Your Account"
      description="Awesomeheap is a great collection of graphics, photos, icons, and more"
      showHeader={false}
    >
      <LoginC />
    </BaseLayout>
  );
}

export default LoginPage;
