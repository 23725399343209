import React, { useEffect, useState } from 'react';
import BaseLayout from '../layouts/BaseLayout';
import IconDetailComponent from '../components/IconDetails/IconDetail';
import { useParams } from 'react-router-dom';

const BACKEND_URL = process.env.REACT_APP_BACK_URL || 'http://localhost:8000';

const IconDetailPage = () => {
  const { slug } = useParams(); // Получаем slug из URL параметров
  const [error, setError] = useState(null);

  useEffect(() => {
    // Проверка на наличие slug
    if (!slug) {
      setError('Slug not provided');
    }
  }, [slug]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <BaseLayout title="" description="">
      <div className="page-container-with-top-margin">
        <IconDetailComponent slug={slug} BACKEND_URL={BACKEND_URL} />
      </div>
    </BaseLayout>
  );
};

export default IconDetailPage;
