// src/pages/TemplateDetailPage.jsx

import React from 'react';
import { useParams } from 'react-router-dom';
import BaseLayout from '../layouts/BaseLayout'; 
import TemplateDetailComponent from '../components/TemplateDetailComponent.jsx';
import PopupMakeALink from '../components/Popupmakealink';


const TemplateDetailPage = () => {
  const { slug } = useParams();
  const PUBLIC_BACK_URL = process.env.REACT_APP_BACK_URL || '';

  return (
    <BaseLayout title="Template Detail" description="Beautiful graphic templates">
      <div className="page-container-with-top-margin">
    
        <TemplateDetailComponent slug={slug} PUBLIC_BACK_URL={PUBLIC_BACK_URL} />
        
      </div>
    </BaseLayout>
  );
};

export default TemplateDetailPage;
