import React, { useEffect, useState } from 'react';
import GalleryComponent from '../../components/Gallery.jsx';

const SimilarPhotos = ({ slug }) => {
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchSimilarPhotos = async () => {
    try {
      setIsLoading(true);

      // console.log('Sending request to server with slug:', slug);

      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/similar-assets`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ slug }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch similar photos');
      }

      const data = await response.json();
      // console.log('Similar photos received:', data);

      const updatedPhotos = await Promise.all(
        data.items.map(async (item) => {
          const baseUrl = item.media_type === 'icon' ? '/icon' : '/photo';
          const photoUrl = `${baseUrl}/${item.slug}`;

          if (!item.url_small_width || !item.url_small_height) {
            try {
              const img = new Image();
              img.src = item.url_small;

              await new Promise((resolve, reject) => {
                img.onload = () => resolve();
                img.onerror = () => reject(new Error(`Image load error for: ${item.url_small}`));
              });

              // console.log(`Image loaded: ${item.url_small}, dimensions: ${img.naturalWidth}x${img.naturalHeight}`);

              return {
                src: item.url_small,
                width: img.naturalWidth,
                height: img.naturalHeight,
                alt: item.title,
                loading: 'lazy',
                key: item.slug,
                url: photoUrl,
                isFree: item.is_free,
              };
            } catch (error) {
              console.error('Error loading image:', error.message);
              return null; // Пропускаем изображения, которые не удалось загрузить
            }
          }

          // console.log(`Using existing image dimensions for: ${item.url_small}`);
          return {
            src: item.url_small,
            width: item.url_small_width,
            height: item.url_small_height,
            alt: item.title,
            loading: 'lazy',
            key: item.slug,
            url: photoUrl,
            isFree: item.is_free,
          };
        })
      );

      // Фильтруем null значения (если изображения не загрузились)
      setPhotos(updatedPhotos.filter((photo) => photo !== null));
    } catch (err) {
      console.error('Error during fetching:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      // console.log('Fetching similar photos for slug:', slug);
      fetchSimilarPhotos();
    } else {
      console.log('No slug provided.');
    }
  }, [slug]);

  return (
    <div>
      <h2>Similar Photos</h2>
      {isLoading && <div>Loading similar photos...</div>}
      {error && <div>{error}</div>}
      {!isLoading && photos.length > 0 && (
        <GalleryComponent photos={photos} />
      )}
      {!isLoading && photos.length === 0 && !error && (
        <div>No similar photos found.</div>
      )}
    </div>
  );
};

export default SimilarPhotos;
