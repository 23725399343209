// BaseLayout.jsx
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header.jsx';
import Footer from '../components/Footer.jsx';
import CookieBanner from '../components/CookieBanner.jsx';


function BaseLayout({
  title,
  description = '',
  showHeader = true,
  canonicalUrl,
  searchQuery = '',
  mediaType = '',
  children,
}) {
  const [currentUrl, setCurrentUrl] = useState(canonicalUrl || '');

  useEffect(() => {
    if (!canonicalUrl) {
      setCurrentUrl(window.location.href);
    }
  }, [canonicalUrl]);

  return (
    <>
      <Helmet>
        {/* Google Tag Manager */}
        <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WG5F72G9');
        `}</script>
        {/* End Google Tag Manager */}

        <meta charSet="UTF-8" />
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="stylesheet" href="../styles/global.css" />
        <link rel="stylesheet" href="../styles/navbar.css" />
        <link rel="stylesheet" href="../styles/searchresults.css" />

        {/* Канонический URL */}
        <link rel="canonical" href={currentUrl} />

        {/* Мета-описание */}
        {description && <meta name="description" content={description} />}
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-WG5F72G9"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      {showHeader && (
        <Header searchQuery={searchQuery} mediaType={mediaType} />
      )}

      <div className="sticky-footer-wrapper">
        {/* Основное содержимое */}
        {children}

        {/* Обертываем Footer и CookieBanner в page-container */}
        <div className="page-container">
          <Footer />
          <CookieBanner />
        </div>
      </div>
    </>
  );
}

export default BaseLayout;
