// src/components/Header.jsx
import React, { useState, useEffect } from 'react';
import Menu from './Menu.jsx';
import '../styles/Global.css';
import '../styles/Footer.css';
import '../styles/Navbar.css';
import '../styles/Popupmakealink.css';

import SearchInput from './SearchInput.jsx';
import Navbar from './Navbar.jsx';
import Logo from './Logo.jsx';

function Header({ searchQuery, mediaType }) {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Обработка прокрутки
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsVisible(false);
      } else if (currentScrollY < lastScrollY) {
        setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    // Удаление обработчика при размонтировании компонента
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div
      className="header-meta"
      style={{
        transform: isVisible ? 'translateY(0)' : 'translateY(-100%)',
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <div className="header">
        <div className="header-container">
          <div className="logo-container">
            <a href="/">
              {/* <img src="/awesomeheap_logo.svg" alt="awesomeheap" style={{ height: '36px' }} /> */}
              <Logo />
            </a>
          </div>
          <div className="search-container">
            <SearchInput searchQuery={searchQuery} mediaType={mediaType} />
          </div>
          <div className="menu-container">
            <div className="mainmenu-button" data-action="openMainMenu">
              <Navbar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;