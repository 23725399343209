// src/pages/CollectionPage.jsx

import React from 'react';
import { useParams } from 'react-router-dom';
import BaseLayout from '../layouts/BaseLayout.jsx';
import CollectionDetail from '../components/CollectionDetails/CollectionDetails';

const CollectionPage = () => {
  const { collection } = useParams();

  // Проверка наличия названия коллекции
  if (!collection) {
    throw new Error('Название коллекции не предоставлено');
  }

  // Доступ к переменным окружения
  const BACK_URL = process.env.REACT_APP_BACK_URL || 'http://localhost:8000';

  // Параметры страницы
  const page = 1; // По умолчанию 1
  const per_page = 200; // По умолчанию 200

  // Логи для отладки
  console.log('BACK_URL в React:', BACK_URL);
  console.log('Страница:', page, 'Элементов на страницу:', per_page);

  return (
    <BaseLayout
      title={`Коллекция: ${collection}`}
      description={`Просмотр всех активов в коллекции ${collection}`}
    >
      <div className="page-container-with-top-margin">
        {/* Передача параметров в CollectionDetail */}
        <CollectionDetail
          collection={collection}
          BACK_URL={BACK_URL}
          page={page}
          per_page={per_page}
        />

        <div style={{ marginTop: '40px' }}>
          {/* Дополнительный контент можно добавить здесь */}
        </div>
      </div>
    </BaseLayout>
  );
};

export default CollectionPage;
