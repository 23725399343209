// PricingPage.jsx
import React from 'react';
import Header from '../components/Header.jsx';
import PricingComponent from '../components/PricingComponent.jsx';
import BaseLayout from '../layouts/BaseLayout.jsx';

function PricingPage() {
  return (
    <BaseLayout
      title="Our Pricing Plans"
      description="Awesomeheap is a great collection of graphics, photos, icons and more"
    >
     
      <div className="small-page-container margintop-container">
        <PricingComponent />
        {/* <SubscriptionDetails /> */}
      </div>
    </BaseLayout>
  );
}

export default PricingPage;
