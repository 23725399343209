import React from 'react';
import { useParams } from 'react-router-dom'; // Импортируем useParams
import BaseLayout from '../layouts/BaseLayout';
import PhotoDetailComponent from '../components/PhotoDetails/PhotoDetail';
import PopupMakeALink from '../components/Popupmakealink';

const PUBLIC_BACK_URL = process.env.REACT_APP_BACK_URL || '';

const AssetDetailPage = () => {
    const { slug } = useParams(); // Получаем slug из параметров URL
    
    return (
        <BaseLayout title="" description="">
            <div className="page-container-with-top-margin">
               
                <PhotoDetailComponent slug={slug} PUBLIC_BACK_URL={PUBLIC_BACK_URL} />
                <PopupMakeALink />
            </div>
        </BaseLayout>
    );
};

export default AssetDetailPage;
