import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import BaseLayout from '../layouts/BaseLayout.jsx';
import InfiniteScrollGallery from '../components/InfiniteScrollGallery.jsx';

function SearchPage() {
  // Extract the 'query' from the URL parameters
  const { query } = useParams();

  // Replace dashes with spaces for a readable format
  const readableQuery = query ? query.replace(/-/g, ' ') : '';

  // Access the query parameters from the URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const media_type = searchParams.get('media_type') || '';
  const is_free_param = searchParams.get('is_free');

  // Convert 'is_free' to a boolean if it's present
  const is_free = is_free_param !== null ? (is_free_param.toLowerCase() === 'true') : null;

  // Construct the canonical URL using the original 'query' with hyphens
  const canonicalUrl = `https://awesomeheap.com/search/${encodeURIComponent(query || '')}`;

  // Access the environment variable for backend URL
  const publicBackUrl = process.env.REACT_APP_BACK_URL;

  return (
    <BaseLayout 
      title={`Search results for "${readableQuery}"`} 
      description={`Search results for "${readableQuery}"`}
      canonicalUrl={canonicalUrl}
      searchQuery={readableQuery} 
      mediaType={media_type} 
    >
      <div className="search-page-container-with-top-margin">
        <InfiniteScrollGallery 
          searchQuery={readableQuery} 
          mediaType={media_type} 
          isFree={is_free} 
          publicBackUrl={publicBackUrl}
        /> 
      </div>
    </BaseLayout>
  );
}

export default SearchPage;
