// additional_storage.js
import { create } from 'zustand';

const useAdditionalStorage = create((set) => ({
  isOpen: false,
  togglePopup: () => set((state) => {
    const newState = !state.isOpen;
    console.log(`togglePopup called. isOpen: ${state.isOpen} -> ${newState}`);
    return { isOpen: newState };
  }),
  setQuery: (newQuery) => set({ query: newQuery }),
  setMediaType: (newMediaType) => set({ mediaType: newMediaType }),
}));

export default useAdditionalStorage;
