import React, { useState, useRef, useEffect } from 'react';
import LensIcon from './LensIcon'; // Import the LensIcon component
import '../styles/Global.css'; // Import the global CSS

const SearchInput = ({ searchQuery: initialQuery, mediaType: initialMediaType }) => {
  const [searchQuery, setSearchQuery] = useState(initialQuery || '');
  const [searchType, setSearchType] = useState(initialMediaType || '');
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim() === '') return;
    setLoading(true);
    const query = searchQuery.trim().replace(/\s+/g, '-');

    // Formulate URL based on selected search type
    let url = `/search/${query}`;
    if (searchType) {
      url += `?media_type=${searchType}`;
    }

    // Simulate delay before navigating to the search page
    setTimeout(() => {
      window.location.href = url;
    }, 500); // Increased delay for better UX
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="search-input-container">
      {loading && <div className="loading-bar" />}
      <form onSubmit={handleSubmit} className="search-form">
        {/* Container for dropdown and search input */}
        <div className="input-wrapper">
          {/* Dropdown Selector */}
          <div
            ref={dropdownRef}
            className="dropdown-selector"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <span className="dropdown-label">
              {searchType ? searchType.replace('-', ' ') : 'All Images'}
            </span>
            <div className={`dropdown-arrow ${dropdownOpen ? 'open' : ''}`} />
            {/* Dropdown Menu */}
            {dropdownOpen && (
              <ul className="dropdown-menu">
                {/* "All Images" Option */}
                <li
                  className={`dropdown-item ${searchType === '' ? 'active' : ''}`}
                  onClick={() => {
                    setSearchType('');
                    setDropdownOpen(false);
                  }}
                >
                  All Images
                </li>
                {/* Other Media Types */}
                {['icon', 'photo', 'template'].map((type) => (
                  <li
                    key={type}
                    className={`dropdown-item ${searchType === type ? 'active' : ''}`}
                    onClick={() => {
                      setSearchType(type);
                      setDropdownOpen(false);
                    }}
                  >
                    {type.replace('-', ' ')}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {/* Search Input Field */}
          <div className="search-input-wrapper">
          <LensIcon  />
            <input
              type="text"
              name="query"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit(e);
                }
              }}
              className="search-input"
            />
          </div>
        </div>
        <button type="submit" className="hidden-button">
          Search
        </button>
      </form>
    </div>
  );
};

export default SearchInput;
