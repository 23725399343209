import React, { useEffect, useState, useRef } from 'react';
import { useUserStore } from '../store'; // Adjust import path if necessary
import EnergyIcon from './EnergyIcon'; // Import the LensIcon component
const Navbar = () => {
  const {
    user,
    avatarUrl,
    portalLink,
    subscriptionEnergy,
    permanentEnergy,
    logoutUser,
    initializeUser
  } = useUserStore();
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const unsubscribe = initializeUser();
    return () => unsubscribe();
  }, [initializeUser]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleManageSubscription = () => {
    if (portalLink) {
      window.location.href = portalLink;
    }
  };

  const totalEnergy = subscriptionEnergy + permanentEnergy; // Суммируем здесь
  const energyTooltip = `Subscription Energy: ${subscriptionEnergy} ⚡, Permanent Energy: ${permanentEnergy} ⚡`;

  const handleLogin = () => {
    window.location.href = '/login'; // Редирект на страницу входа
  };

  return (
    <div className="navbar">
      <a href="/prices" className="navbar-item">Prices</a>
      {user ? (
        <>
         <div className="navbar-item energy" title={energyTooltip} style={{ display: 'flex', alignItems: 'center' }}>
  <div className="energy-value">{totalEnergy}</div>
 
    <EnergyIcon />

</div>

          <div onClick={toggleMenu} className="navbar-item user-menu">
            <img src={avatarUrl || 'default-avatar.png'} alt="User" className="user-image"/>
            {menuVisible && (
              <div ref={menuRef} className="dropdown">
                <div className="menu-item" onClick={handleManageSubscription}>Manage Subscription</div>
                <div className="menu-item" onClick={logoutUser}>Sign out</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div onClick={handleLogin} className="navbar-item" style={{ cursor: 'pointer' }}>Sign in</div>
      )}
    </div>
  );
};

export default Navbar;
