import React from 'react';
import BaseLayout from '../layouts/BaseLayout';

function PrivacyPolicy() {
  return (
    <BaseLayout title="Terms" description="Terms and conditions">
      <div className="page-container-with-top-margin"> 
        
        <h1>Privacy Policy</h1>
        <p><strong>Siarhei Pratasavitski</strong>, NIP 1133073168, REGON 523267617 (“<strong>awesomeheap</strong>” or <strong>“we”, “our”, “us”</strong>), being the owner and operator of <a href="https://awesomeheap.com/">https://awesomeheap.com/</a>, receives, collects, stores, and manages Customer’s personal data according to the following Privacy Policy. We take pride in treating our Customer’s privacy the way we would like to be treated ourselves. Under this Privacy Policy awesomeheap is the controller of Customer’s personal data.</p>
        <p>By using the website <a href="https://awesomeheap.com/">https://awesomeheap.com/</a> (the “<strong>Website</strong>”), you agree to the terms of this Privacy Policy.</p>
        <p>This Privacy Policy applies to the Services provided by Siarhei Pratasavitski, NIP 1133073168, REGON 523267617.</p>
        <p><strong>Our Contact Email Address is info@davooda.com</strong></p>
        
        <h2>Why do we process your data?</h2>
        <ul>
          <li>In connection with the services provided through the Website and for the purpose of concluding or performing a contract (Article 6 (1) (b) of the GDPR)), your data will be stored until your account is terminated for any reasons.</li>
          <li>For marketing purposes and promotion of products offered by awesomeheap, your data will be processed until you object.</li>
          <li>Fulfilment of any legal obligations incumbent on us in connection with the cooperation undertaken (Article 6 (1) (c) of the GDPR), this purpose is related to the legal obligations to keep certain documents for the time specified in the law, e.g.:
            <ul>
              <li>the Accounting Act of 29.09.1994 (Journal of Laws of 2017, item 1858)</li>
              <li>the Tax Ordinance of 29 August 1997 (Journal of Laws of 1997 No. 137, item 926)</li>
              <li>the Counteracting Money Laundering and Terrorist Financing Act of 1 March 2018 (Journal of Laws of 2018 item 723)</li>
            </ul>
          </li>
          <li>For the possible establishment, exercise or defence of claims, which is our legitimate interest (Article 6 (1) (f) of the GDPR), the data will be processed until the claims are submitted.</li>
          <li>In the case of consent, your data will be processed within the limits and for the purpose indicated in the consent (legal basis: Article 6 (1) (a) of the GDPR). The data will be processed until the consent is withdrawn.</li>
        </ul>
        
        <h2>What data is being processed?</h2>
        <h3>Data that you voluntarily provide us with:</h3>
        <p>You may provide us with your personal data in a number of ways, including, without limitation, when you sign up for and use the Services; have previously provided us with personal information prior to this Privacy Policy or last updates coming into effect; purchase any Visual Content or make any payment; subscribe to any alerts, information releases, news, newsletters, updates and media releases; complete and submit any forms to us; post any content on or through the Website; contact us directly in person or via any medium including but not limited to by mail, telephone, social media and commercial electronic messages; communicate with us for any matters and interact with or browse the Website generally.</p>
        <p>Personal data that may be provided by you includes, but is not limited to:</p>
        <ul>
          <li>Contact information such as your full name, current and valid email address;</li>
          <li>Account password;</li>
          <li>Physical address;</li>
          <li>Phone number;</li>
          <li>Billing information and purchase history;</li>
          <li>More detailed contact preferences;</li>
          <li>Location data.</li>
        </ul>
        <p>By giving us this data, you consent to this data being collected, used, disclosed, transferred to Europe, and stored by us, as described in our Terms and this Privacy Policy.</p>
        
        <h3>Data that we collect automatically</h3>
        <p>We collect data from every visitor and customer of the Website, we automatically collect data about your visit to the Website, your usage of the Services and your website browsing activities. This anonymized information may include IP address, browser type, browser language, cookies data, performance specifications of the user’s hardware and software, date, and time of access to the Website and the URL of the page requested, other information about how you interacted with our Website and our Services.</p>
        
        <h3>Data from your use of the Services</h3>
        <p>We may receive data about how and when you use the Services, store it in log files or other types of files associated with your account, and link it to other information we collect about you. This data may include, for example, your IP address, time, date, browser used, and actions you have taken within the Website. This type of data helps us to improve our Services for both you and for all of our users.</p>
        
        <h3>Cookies and tracking</h3>
        <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Website owners use cookies to make their websites operate, improve functionality and user experience, as well as to provide reporting information.</p>
        <p>We may set our own cookies and use third parties cookies (e.g., for advertising, interactive content, and analytics).</p>
        <p>We use different types of cookies, including:</p>
        <ul>
          <li><strong>Essential website cookies:</strong> these are important cookies as they are necessary to provide you with services available through our Website, such as access to secure areas.</li>
          <li><strong>Performance and functionality cookies:</strong> these cookies enhance the performance and functionality of our Website but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.</li>
          <li><strong>Analytics and customization cookies:</strong> these cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you in order to enhance your experience.</li>
          <li><strong>Advertising cookies:</strong> these cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed, and in some cases selecting advertisements that are based on your interests.</li>
        </ul>
        <p>You may opt out from cookies at any time by customizing your cookie preferences. However, in some cases, we will not be able to provide you with all services.</p>
        <p>By browsing and using our Website and Services, you agree to the use of cookies and similar technologies as stated in this Privacy Policy.</p>
        
        <h3>Web beacons and similar technologies</h3>
        <p>Apart from cookies, we use other similar technologies, like web beacons, to monitor traffic, deliver and communicate with cookies, and improve site performance. Similar to cookies, you may opt out from web beacons at any time.</p>
        
        <h3>Data from third-party sources</h3>
        <p>We may receive some information about you, such as name, email address, demographic information, IP address, location from third parties.</p>
        <p>We collect information about your activity on our Website to enable us to:</p>
        <ul>
          <li>Measure and analyze traffic and browsing activity on our Website;</li>
          <li>Show advertisements for our products and/or services to you on third-party sites;</li>
          <li>Measure and analyze the performance of our advertising campaigns.</li>
        </ul>
        
        <h3>Opting-Out</h3>
        <p>You may decline to have your personal data collected via third-party tracking technologies by navigating to the settings feature in your browser and declining all third-party cookies or declining third-party cookies from specific sites, or, for mobile, limiting ad tracking or resetting the advertising identifier via the privacy settings on your mobile device.</p>
        <p>You can use the following third-party tools to decline the collection and use of information for the purpose of serving you interest-based advertising:</p>
        <ul>
          <li><a href="http://www.networkadvertising.org/choices/">The NAI’s opt-out platform</a></li>
          <li><a href="http://www.youronlinechoices.com/">The EDAA’s opt-out platform</a></li>
          <li><a href="http://optout.aboutads.info/?c=2&lang=EN">The DAA’s opt-out platform</a></li>
        </ul>
        
        <h2>Who will the data be shared with?</h2>
        <p>We do not share personal data you have provided to us without your consent, unless:</p>
        <ul>
          <li>Doing so is appropriate to carry out your own request;</li>
          <li>We believe it is needed to enforce our Terms, or if it is legally required;</li>
          <li>We believe it is needed to detect, prevent or address fraud, security, or technical issues;</li>
          <li>Otherwise protect our property, legal rights, or that of others.</li>
        </ul>
        <p>As per above, we may need to share personal data with our service providers that perform certain tasks on our behalf and who are under our control (our “Service Providers”) in order to provide products or services to you. Unless we tell you differently, our Service Providers do not have any right to use personal data or other information we share with them beyond what is necessary to assist us. You hereby consent to our sharing of personal data with our Service Providers, namely:</p>
        
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Provider</th>
              <th>Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Stripe</td>
              <td>Stripe is a payment service provided by Stripe Payments Ltd.</td>
              <td>Handling payments</td>
            </tr>
          </tbody>
        </table>
        
        <p>To receive the current list of Service Providers that we use for this purpose, please email at info@awesomeheap.com.</p>
        
        <h2>Do we Collect Data of Minors?</h2>
        <p>Minors and children under the age of 18 (or other legal age of your country of residence) should not use our Services. If you are 13 years of age up to 18, you need to obtain a written parental or guardian consent, unless the law of the country of your residence prescribes higher age requirements, in which case you need to meet such requirements. We do not knowingly provide any Services to minors. If you access our Services, you represent that you have the legal capacity to enter into a binding agreement.</p>
        
        <h2>Are Data Profiled?</h2>
        <p>Please be advised that your data will not be subject to profiling, i.e., automated analysis of your data and development of predictions about preferences or future behavior (profiling means, e.g., in the case of marketing profiling, determining which offer you may be most interested in based on your previous choices).</p>
        
        <h2>How do we use your Data?</h2>
        <p>We only use your personal data for the following purposes:</p>
        <ul>
          <li>To provide you with the Services that you requested;</li>
          <li>To promote the use of our Services to you;</li>
          <li>To analyze and improve our Services;</li>
          <li>To send you informational and promotional content that you may subscribe to or unsubscribe from;</li>
          <li>To bill and collect money for Visual Content ordered;</li>
          <li>To send you notifications, including notifications on changes of our Services;</li>
          <li>To communicate with our Customers about our Website and Services and provide customer support in response to their inquiries;</li>
          <li>To enforce compliance with our Terms and applicable law;</li>
          <li>To protect our rights and safety as well as the rights and safety of our customers and third parties;</li>
          <li>To meet legal requirements;</li>
          <li>To provide information to representatives and Service Providers;</li>
          <li>To prosecute and defend a court, arbitration, or similar legal proceeding;</li>
          <li>To respond to lawful requests by public authorities, including to meet national security or law enforcement requirements;</li>
          <li>To provide suggestions about products or services that you may be interested in and which you may opt out;</li>
          <li>To transfer your information in the case of a sale, merger, consolidation, liquidation, reorganization, or acquisition.</li>
        </ul>
        
        <h2>What are your rights?</h2>
        <ul>
          <li><strong>Right of access to personal data processed by us</strong> (Article 15 of the GDPR).</li>
          <li><strong>Right to rectify entrusted personal data</strong>, including their correction (Article 16 of the GDPR).</li>
          <li><strong>Right to delete personal data from our systems</strong>, the so-called “right to be forgotten”, if in your opinion there are no grounds for us to process your data, you can request that we delete it (Article 17 of the GDPR).</li>
          <li><strong>Right to restrict the processing of personal data.</strong> You may request that we restrict the processing of personal data only to their storage or to the performance of activities agreed with you, if we have incorrect data about you or process them unjustifiably; or you do not want us to delete them because they are necessary for you to establish, pursue or defend claims; or for the duration of the objection to data processing (Article 18 of the GDPR).</li>
          <li><strong>Right to data portability.</strong> You have the right to receive from us, in a structured, commonly used, and machine-readable format (e.g., “.csv” format), personal data relating to you held by us on the basis of a contract or consent. This right will be granted when we have data in electronic format. If data is only in paper form, you will not be able to use this right. You can commission us to transfer this data directly to another entity (Article 20 of the GDPR).</li>
          <li><strong>The right to withdraw consent to the processing of personal data.</strong> At any time, you have the right to withdraw consent to the processing of personal data that we process on the basis of consent in accordance with Article 7 item 3 of the GDPR. The withdrawal of consent shall not affect the lawfulness of any processing performed on the basis of your consent prior to its withdrawal. Withdrawal of consent occurs by sending an e-mail to info@awesomeheap.com.</li>
          <li><strong>Right to object.</strong> You may object to the processing of your data if the basis for the use of data is our legitimate interest in accordance with Article 21 of the GDPR. In such a situation, after examining your request, we will no longer be able to process the personal data subject to the objection on this basis, unless we demonstrate the existence of legitimate grounds for the processing that are considered to override your interests, rights, and freedoms.</li>
          <li>If, in your opinion, the processing of personal data violates the provisions of the GDPR, you have the right to lodge a complaint with the supervisory authority, i.e., President of the Personal Data Protection Office.</li>
        </ul>
        
        <h2>Changes to the Privacy Policy</h2>
        <p>We may amend this Privacy Policy from time to time. Use of information we collect now is subject to the Privacy Policy in effect at the time such information is used. If we make major changes in the way we collect or use information, we will notify you by posting an announcement on the Website or sending you an email.</p>
        
        <h2>Merge of Company</h2>
        <p>If awesomeheap merges with, or is acquired by, another company or organization, or sells all or a portion of its assets, your personal data may be disclosed to our advisers and any prospective purchaser or any prospective purchaser’s adviser and may be among the assets transferred. However, personal data will always remain subject to this Privacy Policy.</p>
        
        <h2>Data Retention</h2>
        <p>We retain your personal data primarily within the European Union. We retain your personal data for as long as we need it to fulfill the purposes for which we originally collected it or as needed to provide the Services, except if required otherwise by law. In some cases, we may determine the period of data retention based on the period we need to access the data for the provision of services, receiving payment, or for any other auditing or legal purposes.</p>
        <p>Countries where we process information may have laws that are different, and potentially not as protective, as the laws of your own country, though we will always comply with the applicable laws when we process any information.</p>
        
        <h2>Contact</h2>
        <p>If you need any additional information related to personal data protection or would like to exercise your rights, please contact us at the e-mail address: <a href="mailto:info@awesomeheap.com">info@awesomeheap.com</a></p>

      </div>
    </BaseLayout>
  );
}

export default PrivacyPolicy;
