import React, { useEffect, useState, useRef } from 'react';
import GalleryComponent from '../components/Gallery.jsx';
import { useUserStore } from '../store'; // Import the user store to get email
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Auth

const InfiniteScrollGallery = ({
  searchQuery,
  publicBackUrl,
  mediaType,
  isFree,
}) => {
  const [photos, setPhotos] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const maxPages = 15;
  const [pageCount, setPageCount] = useState(1);

  // State to track if auth state is initialized
  const [authInitialized, setAuthInitialized] = useState(false);

  // State to track fetching status
  const [isFetching, setIsFetching] = useState(false);

  // Refs to hold the latest values
  const lastCombinedScoreRef = useRef(null);
  const lastIdRef = useRef(null);
  const isFetchingRef = useRef(false);
  const hasMoreRef = useRef(true);
  const searchIdRef = useRef(null);

  // Get email from the user store
  const email = useUserStore((state) => state.email);

  // Wait for auth state to be initialized
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, () => {
      setAuthInitialized(true);
    });
    return () => unsubscribe();
  }, []);

  const fetchPhotos = async () => {
    if (isFetchingRef.current || !hasMoreRef.current) return;

    // Limit the number of pages
    if (pageCount > maxPages) {
      setHasMore(false);
      hasMoreRef.current = false;
      return;
    }

    isFetchingRef.current = true;
    setIsFetching(true); // Start fetching

    try {
      const formattedQuery = searchQuery.replace(/\-/g, ' ');

      const requestBody = {
        search_request: formattedQuery,
        last_combined_score: lastCombinedScoreRef.current,
        last_id: lastIdRef.current,
        media_type: mediaType || null,
        is_free: isFree !== null && isFree !== undefined ? isFree : null,
        user_id: email || null, // Pass email as user_id
        page: pageCount,
        search_id: searchIdRef.current || null,
      };

      const response = await fetch(`${publicBackUrl}/search`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      const data = await response.json();

      // Store search_id from the response if not already set
      if (!searchIdRef.current && data.search_id) {
        searchIdRef.current = data.search_id;
      }

      if (data.items && data.items.length > 0) {
        const newPhotos = data.items.map((item) => ({
          src: item.url_small,
          width: item.url_small_width || 100,
          height: item.url_small_height || 100,
          alt: item.title,
          loading: 'lazy',
          key: item.slug,
          url:
            item.media_type === 'icon'
              ? `/icon/${item.slug}`
              : `/photo/${item.slug}`,
          isFree: item.is_free,
          mediaType: item.media_type,
          rating: item.rating,
          similarity: item.similarity,
          combinedScore: item.combined_score,
        }));

        setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);

        // Update refs with the latest values
        lastCombinedScoreRef.current = data.last_combined_score;
        lastIdRef.current = data.last_id;

        setPageCount((prevPage) => prevPage + 1);

        if (pageCount >= maxPages) {
          setHasMore(false);
          hasMoreRef.current = false;
        }
      } else {
        setHasMore(false);
        hasMoreRef.current = false;
      }
    } catch (error) {
      console.error('Failed to fetch photos:', error);
      setHasMore(false);
      hasMoreRef.current = false;
    } finally {
      isFetchingRef.current = false;
      setIsFetching(false); // End fetching
    }
  };

  // Fetch photos when parameters change and auth is initialized
  useEffect(() => {
    if (!authInitialized) return;

    setPhotos([]);
    setPageCount(1);
    setHasMore(true);
    hasMoreRef.current = true;
    lastCombinedScoreRef.current = null;
    lastIdRef.current = null;
    searchIdRef.current = null;

    // Fetch photos immediately
    fetchPhotos();

    // Reset isFetchingRef to false in case it was left true
    isFetchingRef.current = false;
  }, [searchQuery, mediaType, isFree, authInitialized]);

  // Infinite scroll handler
  useEffect(() => {
    const handleScroll = () => {
      if (!hasMoreRef.current || isFetchingRef.current) return;

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop + windowHeight >= documentHeight * 0.75) {
        fetchPhotos();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [photos]); // Depend on photos to re-attach the event when photos change

  return (
    <div>
      <div className="page-container">
        <h1 className='search-results-title'>Search results for "{searchQuery.replace(/\-/g, ' ')}"</h1>
      </div>

      <GalleryComponent photos={photos} />

      <div className="page-container">
        {isFetching && <div>Loading...</div>}
        {!hasMore && <div>No more results.</div>}
      </div>
    </div>
  );
};

export default InfiniteScrollGallery;
