import React, { useEffect, useState } from 'react';
import '../../styles/CollectionDetail.css'; // Adding styles in a separate file

const CollectionDetail = ({ collection, BACK_URL, page: initialPage, per_page: initialPerPage }) => {
    const [assets, setAssets] = useState([]);
    const [page, setPage] = useState(Number(initialPage) || 1);
    const [perPage] = useState(Number(initialPerPage) || 50);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCollection = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(`${BACK_URL}/collection-detail`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        collection,
                        page,
                        per_page: perPage
                    })
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }

                const data = await response.json();
                setAssets(data.items);
                setTotalPages(data.total_pages);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCollection();
    }, [collection, page, perPage, BACK_URL]);

    useEffect(() => {
        // Update URL when page changes
        const url = new URL(window.location);
        url.searchParams.set('page', page);
        window.history.pushState({}, '', url);
    }, [page]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    if (loading) return <p>Loading collection...</p>;
    if (error) return <p>An error occurred: {error}</p>;
    if (assets.length === 0) return <p>No assets in this collection.</p>;

    return (
        <div>
            <h2>Collection: {collection}</h2>
            <div className="assets-grid">
                {assets.map(asset => (
                    <a 
                        key={asset.asset_id} 
                        href={`/${asset.media_type}/${asset.slug}`} 
                        className="asset-card"
                    >
                        <img src={asset.url_medium} alt={asset.title} className="collection-asset-image" />
                    </a>
                ))}
            </div>
            <div className="pagination">
                <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                    Previous
                </button>
                <span>Page {page} of {totalPages}</span>
                <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages || totalPages === 0}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default CollectionDetail;
