import React, { useState } from 'react';

function DrawerMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const drawerStyle = {
        position: 'fixed',
        top: 0,
        right: isOpen ? 0 : '-320px',  // Выезд меню
        width: '320px',
        height: '100%',
        backgroundColor: 'white',
        transition: 'right 0.4s ease, opacity 0.4s ease', // Плавное изменение позиции и прозрачности
        opacity: isOpen ? 1 : 0,  // Контроль прозрачности
        zIndex: 1001
    };

    const backdropStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        transition: 'opacity 0.4s ease', // Плавное изменение прозрачности
        opacity: isOpen ? 1 : 0,  // Контроль прозрачности
        zIndex: 1000,
        display: 'block',
        pointerEvents: isOpen ? 'auto' : 'none' // Отключает кликабельность когда меню закрыто
    };

    return (
        <div>
            <img src="/images/burger_menu.svg" alt="Menu" className="menu-burger-icon" onClick={() => setIsOpen(!isOpen)} />
            <div style={backdropStyle} onClick={() => setIsOpen(false)}></div>
            <div style={drawerStyle}>
                <img src="/images/close_cross_big.svg" alt="Close" style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer', width: '20px', height: '20px' }} onClick={() => setIsOpen(false)} />
                <a href="#" style={{ display: 'block', padding: '10px' }}>Login</a>
                <a href="/prices" style={{ display: 'block', padding: '10px' }}>Prices</a>
                <a href="#" style={{ display: 'block', padding: '10px' }}>Explore photos</a>
            </div>
        </div>
    );
}

export default DrawerMenu;
