import React from 'react';
import '../styles/Popupmakealink.css';

import useAdditionalStorage from '../additional_storage';

const PopupMakeALink = () => {
  const { isOpen, togglePopup } = useAdditionalStorage();

  return (
    <>
      {isOpen && (
        <div className="popup-overlay" onClick={() => togglePopup()}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h2>Make a link to our website</h2>
            <p>
              We provide you with high-quality assets, and in return, you include a link to <a href="https://awesomeheap.com" target="_blank" rel="noopener noreferrer">awesomeheap.com</a> wherever they're used. 
              This exchange enhances your content at no cost while increasing our visibility—a win-win!
              <a href="https://awesomeheap.com/examples" target="_blank" rel="noopener noreferrer"> More examples</a>.
            </p>
            <img src="/images/image_by_awesomeheapcom.png" alt="Image from Awesomeheap.com" className="popup-image" />
            <button className="download-button" onClick={() => togglePopup()}>I will do it</button>

          </div>
        </div>
      )}
    </>
  );
};

export default PopupMakeALink;
