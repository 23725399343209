// src/components/ImageDetail.js
import React, { useEffect, useRef } from 'react';
import { useAssetStore } from '../../store_asset_detail';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import '../../styles/AssetDetail.css';
import DownloadPhotoButton from './../DownloadPhotoButton';
import SimilarPhotos from './SimilarPhotos';

const PhotoDetail = () => {
  const { slug } = useParams();
  const { asset, loading, error, fetchAssetBySlug, clearAsset } = useAssetStore();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (slug) {
      // Фетчим ассет только если есть slug
      fetchAssetBySlug(slug);
    }

    return () => {
      // Очищаем ассет при размонтировании компонента или смене slug
      clearAsset();
    };
  }, [slug, fetchAssetBySlug, clearAsset]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  if (!loading && !asset) {
    return <div className="asset-not-found">Asset not found</div>;
  }

  // Обработка размеров для расчета соотношения сторон
  const width = parseInt(asset.url_small_width, 10);
  const height = parseInt(asset.url_small_height, 10);
  const aspectRatio = width && height ? width / height : 16 / 9;

  const keywords = Array.isArray(asset.keywords)
    ? asset.keywords.join(', ')
    : asset.keywords || 'Keywords not provided';

  const freeOrPaid = asset.is_free ? 'This image is free' : 'This image is premium';

  return (
    <div className="image-detail-container">
      <Helmet>
        <title>{asset.title}</title>
        <meta
          name="description"
          content={asset.description || 'Beautiful photo for commercial use.'}
        />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={asset.title} />
        <meta
          property="og:description"
          content={asset.description || 'Beautiful photo for commercial use.'}
        />
        <meta property="og:image" content={asset.url_medium} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="image-detail-content">
        <div className="image-container">
          <img
            src={asset.url_medium}
            alt={asset.title || 'Image'}
            className="asset-image"
            loading="lazy"
            style={{ aspectRatio: `${aspectRatio}` }}
          />
        </div>
        <div className="image-info">
          <h1 className="small-h1-title">{asset.title}</h1>
          <p className="keywords">
            <strong>Keywords:</strong> {keywords}
          </p>
          <p>{freeOrPaid}</p>
          <DownloadPhotoButton />
         
        </div>
      </div>
      <SimilarPhotos slug={slug} />
    </div>
  );
};

export default PhotoDetail;
