import React, { useEffect, useState, useRef } from 'react';

// Debug flag
const DEBUG = false;

// Function to generate unique ID
const generateUniqueId = (() => {
  let counter = 0;
  return () => `icon-display-${++counter}`;
})();

// Function to convert color to RGB
const parseColor = (color) => {
  let r, g, b;
  try {
    if (color.startsWith('#')) {
      // Handle HEX color
      const hex = color.slice(1);
      if (hex.length === 3) {
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
      } else if (hex.length === 6) {
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
      }
    } else if (color.startsWith('rgb')) {
      // Handle RGB/RGBA color
      const match = color.match(/rgba?\(([^)]+)\)/);
      if (match) {
        const values = match[1].split(',').map(v => parseInt(v.trim(), 10));
        [r, g, b] = values;
      }
    }
  } catch (error) {
    console.error(`Error parsing color "${color}":`, error);
  }
  return { r: r || 0, g: g || 0, b: b || 0 };
};

// Function to calculate brightness
const getBrightness = ({ r, g, b }) => {
  // Perception brightness formula
  return 0.299 * r + 0.587 * g + 0.114 * b;
};

// Function to extract unique colors from SVG text
const extractColorsFromText = (text) => {
  const colorSet = new Set();
  const colorRegex = /#([0-9a-fA-F]{3,6})\b|rgba?\(([^)]+)\)/g;
  let match;
  while ((match = colorRegex.exec(text)) !== null) {
    const color = match[0].toLowerCase();
    if (color !== 'none') { // Exclude 'none'
      colorSet.add(color);
    }
  }
  return Array.from(colorSet);
};

// Function to sort colors by brightness
const sortColorsByBrightness = (colorArray) => {
  return colorArray
    .map(color => ({ color, brightness: getBrightness(parseColor(color)) }))
    .sort((a, b) => a.brightness - b.brightness)
    .map(item => item.color);
};

const IconDisplay = ({ svgData, colors = {}, assetTitle, assetKeywords, backgroundColor, onSvgUpdate }) => {
  const [updatedSvgData, setUpdatedSvgData] = useState('');
  const uniqueIdRef = useRef(generateUniqueId()); // Generate unique ID on mount

  useEffect(() => {
    if (!svgData) return;

    try {
      // Parse SVG and set necessary attributes
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(svgData, 'image/svg+xml');
      const svgElement = xmlDoc.documentElement;

      // Set mandatory attributes
      svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      svgElement.setAttribute('width', '100%');
      svgElement.setAttribute('height', '100%');

      // Set viewBox if not present
      if (!svgElement.getAttribute('viewBox')) {
        const width = svgElement.getAttribute('width') || '260';
        const height = svgElement.getAttribute('height') || '260';
        svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
      }

      // Serialize SVG back to string
      let serializedSvg = new XMLSerializer().serializeToString(xmlDoc);

      // Extract all colors from SVG text
      const extractedColors = extractColorsFromText(serializedSvg);

      // Convert input colors from props to array
      const inputColors = Object.values(colors);

      // Sort extracted and input colors by brightness
      const sortedExtractedColors = sortColorsByBrightness(extractedColors);
      const sortedInputColors = sortColorsByBrightness(inputColors);

      if (DEBUG) {
        console.log('Extracted colors:', extractedColors);
        console.log('Input colors for replacement:', inputColors);
        console.log('Sorted extracted colors:', sortedExtractedColors);
        console.log('Sorted input colors:', sortedInputColors);
      }

      // Check if there are enough input colors
      if (sortedInputColors.length < sortedExtractedColors.length) {
        console.warn('Not enough input colors to replace all SVG colors. Some colors will remain unchanged.');
      }

      // Create a color mapping: original color -> new color
      const colorMapping = {};
      sortedExtractedColors.forEach((color, index) => {
        if (sortedInputColors[index]) {
          colorMapping[color] = sortedInputColors[index];
        }
      });

      if (DEBUG) {
        console.log('Color mapping:', colorMapping);
      }

      // Replace colors in SVG text with new colors
      Object.entries(colorMapping).forEach(([originalColor, newColor]) => {
        // Escape special characters for RegExp
        const escapedColor = originalColor.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
        const regex = new RegExp(escapedColor, 'gi');
        serializedSvg = serializedSvg.replace(regex, newColor);
      });

      // Add unique prefix to classes
      const uniquePrefix = `${uniqueIdRef.current}-`;
      serializedSvg = serializedSvg.replace(/class="([^"]+)"/g, (match, classNames) => {
        const updatedClasses = classNames
          .split(' ')
          .map(cls => cls ? `${uniquePrefix}${cls}` : '')
          .filter(Boolean)
          .join(' ');
        return `class="${updatedClasses}"`;
      });

      // Add unique prefix to selectors in <style>
      serializedSvg = serializedSvg.replace(/<style[^>]*>([\s\S]*?)<\/style>/g, (match, styleContent) => {
        // Replace only class selectors without affecting CSS properties
        const updatedStyle = styleContent.replace(/\.([a-zA-Z0-9_-]+)(?=[\s,{])/g, `.${uniquePrefix}$1`);
        return `<style>${updatedStyle}</style>`;
      });

      // Add transition to SVG
      serializedSvg = serializedSvg.replace(/<svg([^>]*)>/, `<svg$1 style="transition: all 0.5s;">`);

      setUpdatedSvgData(serializedSvg);

      // Pass the updated SVG data back to the parent component
      if (onSvgUpdate) {
        onSvgUpdate(serializedSvg);
      }

      if (DEBUG) {
        console.log('Serialized SVG:', serializedSvg);
      }
    } catch (error) {
      console.error('Error processing SVG:', error);
    }
  }, [svgData, colors, onSvgUpdate]);

  return (
    <div style={{ width: '80%', height: '80%', backgroundColor }}>
      <div
        dangerouslySetInnerHTML={{ __html: updatedSvgData }}
        style={{ width: '100%', height: '100%', transition: 'all 0.5s' }}
        aria-label={assetTitle}
        role="img"
        title={assetTitle}
      />
    </div>
  );
};

export default IconDisplay;
